import { OpenInNewOutlined } from "@mui/icons-material";
import { Avatar, Box, Button, Grid, Stack, Typography, darken, lighten, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getOrDefault, verifyNonNull } from "../../../Helpers/Utils";
import { getText, getTextColor } from "../utils";

const CallToActionBanner = ({ spec, type, index }) => {
  const theme = useTheme();
  const navigate = useNavigate()

  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const mainTitle = getOrDefault(spec.mainTitle, {})
  const mainTitleText = getText(mainTitle, `Main title in ${type}`)
  const mainTitleColor = getTextColor(mainTitle.color)

  const description = getOrDefault(spec.description, {})
  const descriptionList = getOrDefault(description.list, [])
  const descriptionColor = getTextColor(description.color)

  const mainPrimaryColor = theme.palette.primary.main
  const darkenedPrimaryColor = darken(mainPrimaryColor, 0.2)

  const mainBackgroundColor = getOrDefault(spec.mainBackgroundColor, mainPrimaryColor)

  const imageURL = spec.imageURL
  verifyNonNull(imageURL, `Image URL in ${type} in index ${index}`)

  const imageBackgroundColor = getOrDefault(spec.imageBackgroundColor, lighten(mainBackgroundColor, 0.5))
  const imageBackgroundRadius = getOrDefault(spec.imageBackgroundRadius, "0%")

  const buttons = getOrDefault(spec.buttons, [])

  return (
    <Box
      id={`${type}-main-box-${index}`}
      sx={{
        backgroundColor: mainBackgroundColor,
        width: "100v",
        py: 5,
        borderRadius: "0 0vw",
        overflowY: "hidden",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Grid sx={{ display: "flex" }} item xs={12} md={8}>
          <Stack
            gap={4}
            direction="column"
            sx={{ width: "100%", maxWidth: 700, alignItems: "center", justifyContent: "center", marginX: 10 }}
          >
            <Typography variant="h1"
              sx={{ 
                color: mainTitleColor,
                textAlign: "start",
              }}>
              {mainTitleText}
            </Typography>
            {
              Object.values(descriptionList).map((value, i) => {
                return (
                  <Typography
                    id={`${type}-main-box-${index}-description-item-${i}`}
                    key={`${type}-main-box-${index}-description-item-${i}`}
                    variant="h3"
                    sx={{
                      fontSize: 18,
                      textAlign: "justify",
                      color: descriptionColor,
                      lineHeight: 1.5,
                    }}
                  >
                    {value}
                  </Typography>
                )
              })
            }
            <Stack sx={{ display: "flex", flexDirection: 'row', zIndex: 2, gap: 2, width: "100%", alignItems: "center", justifyContent: "center" }}>
              {
                Object.values(buttons).map((value, i) => {
                  value.redirectTo = value.redirectTo ? value.redirectTo : {}
                  const buttonBg = getOrDefault(value.backgroundColor, darkenedPrimaryColor)

                  return (
                    <Button
                      id={`${type}-main-box-${index}-button-item-${i}`}
                      key={`${type}-main-box-${index}-button-item-${i}`}
                      variant={getOrDefault(value.variant, "contained")}
                      sx={{
                        background: buttonBg,
                        color: getOrDefault(value.color, "#ffffff")
                      }}
                      onClick={() => {
                        if (value.redirectTo.external) {
                          window.open(value.redirectTo.link, "_blank")
                        } else {
                          navigate(value.redirectTo.link)
                        }
                      }}
                      startIcon={value.redirectTo.external && <OpenInNewOutlined />}
                    >
                      {value.text}
                    </Button>
                  )
                })
              }
            </Stack>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: matches ? "none" : "flex",
          }}
        >
          <Box
            sx={{
              width: "100%",
              backgroundColor: imageBackgroundColor,
              borderRadius: imageBackgroundRadius,
              // height: "50%",
              maxWidth: "60%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Avatar
              alt="placeholder"
              src={imageURL}
              style={{
                width: "92.5%",
                height: "92.5%",
              }}
              sx={{
                borderRadius: imageBackgroundRadius
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default CallToActionBanner;
