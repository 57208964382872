import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Stack,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "./cardWithTextAndCounters.css";

const CardWithTextAndCountersSection = () => {
  const theme = useTheme();
  const matchMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const counters = [
    {
      title: "Effect",
      counter: 291,
      chip: "PRO",
    },
    {
      title: "Effort",
      counter: "19K",
      chip: "PRO",
    },
    {
      title: "Remaining",
      counter: "12K",
      chip: "PRO",
    },
  ];
  return (
    <Container
      sx={{
        p: 1,
        background: "rgb(203,0,255)",
        // background:
        //   "-moz-linear-gradient(27deg, rgba(203,0,255,1) 0%, rgba(29,29,250,1) 100%)",
        // background:
        //   "-webkit-linear-gradient(27deg, rgba(203,0,255,1) 0%, rgba(29,29,250,1) 100%)",
        // background:
        //   "linear-gradient(27deg, rgba(211,211,211,0.925) 0%, rgba(203,0,255,0.8) 100%)",
        width: "100% !important",
        maxWidth: "100% !important",
        position: "relative",
        pb: 6,
      }}
    >
      <Card
        sx={{
          background: "rgba(255, 255, 255, 0.25)",
          boxShadow: "0 0 20px 1px rgba(0, 0, 0, 0.25)",
          backdropFilter: `blur(8px)`,
          WebkitBackdropFilter: `blur(8px)`,
          borderRadius: 5,
          width: "100%",
          height: "100%",
          maxWidth: 1100,
          mx: "auto",
          mt: 1,
          py: 2,
        }}
        elevation={0}
      >
        <CardContent sx={{ width: "100%" }}>
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            {counters.map((counter) => (
              <Grid
                sx={{ position: "relative", zIndex: 3 }}
                item
                key={counter.title}
                xs={12}
                sm={4}
                md={3}
              >
                <Stack
                  sx={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "flex-start",
                    // pt: matchMdDown ? 4 : 8,
                    pl: !matchMdDown && 8,
                  }}
                  gap={3}
                >
                  <Typography
                    sx={{
                      textAlign: "start",
                      fontSize: 18,
                      zIndex: 3,
                    }}
                    variant="body1"
                    color="primary.contrastText"
                  >
                    {counter.title}
                  </Typography>
                  <Stack
                    sx={{
                      alignItems: "center",
                      justifyContent: matchMdDown && "center",
                    }}
                    direction="row"
                  >
                    <Typography
                      sx={{
                        fontSize: matchMdDown ? 35 : 45,
                        fontWeight: 800,
                        textAlign: "start",
                        zIndex: 3,
                      }}
                      variant="h1"
                      color="primary.contrastText"
                    >
                      {counter.counter}
                    </Typography>
                    {counter.chip && (
                      <Chip
                        label={counter.chip}
                        variant="filled"
                        sx={{
                          background: alpha("rgb(203,0,255)", 0.25),
                        }}
                      />
                    )}
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>

      <figure
        style={{
          zIndex: 0,
          top: -30,
          right: 0,
          width: 32,
          height: 32,
          filter: "opacity(0.5)",
          // display: "onl",
        }}
        className="ball"
      />
      <Box
        sx={{
          position: "absolute",
          width: 300,
          height: 300,
          zIndex: 0,
          bottom: 0,
          right: 150,
        }}
        className="stage"
        key="animated big ball box"
      >
        <figure
          key="animated big ball"
          style={{
            bottom: 5,
            left: 0,
            position: "absolute",
          }}
          className="ball bubble"
        />
        <figure
          key="animated small ball "
          style={{
            zIndex: 0,
            bottom: 1,
            left: 0,
            width: 64,
            height: 64,
            position: "absolute",
          }}
          className="ball bubble"
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          zIndex: 0,
          bottom: 0,
          right: 0,
          width: "100%",
        }}
      >
        <figure
          style={{
            zIndex: 0,
            bottom: 2,
            left: 0,
            width: 32,
            height: 32,
            filter: "opacity(0.5)",
            display: "block",
          }}
          className="ball purble"
        />

        <figure
          style={{
            zIndex: 0,
            bottom: 2,
            left: "25%",
            width: 32,
            height: 32,
            filter: "opacity(0.5)",
            display: "block",
          }}
          className="ball purble"
        />
      </Box>
      <Stack
        gap={3}
        sx={{
          width: "100%",
          position: "relative",
          zIndex: 1,
          mt: !matchMdDown && 6,
          maxWidth: 900,
          ml: 6
        }}
      >
        <Typography
          sx={{
            fontSize: matchMdDown ? 35 : 45,
            fontWeight: 800,
            textAlign: "start",
            zIndex: 3,
            // pl: 5,
          }}
          variant="h1"
          color="primary.contrastText"
        >
          The best choise to track your savings
        </Typography>
        <Typography
          sx={{
            textAlign: "start",
            fontSize: 18,
            zIndex: 3,
            // pl: 6,
            maxWidth: 650,
          }}
          variant="body1"
          color="primary.contrastText"
        >
          lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem
          ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
          lorem ipsum
        </Typography>
        {/* <Box sx={{ width: "100%" }}> */}
        <Button
          sx={{
            background:
              "linear-gradient(27deg, rgba(29,29,250,0.51) 0%, rgba(203,0,255,0.51) 100%)",
            maxWidth: 220,
            color: theme.palette.primary.contrastText,
            fontSize: 16,
            py: "11px",
            // ml: 6,
            mt: 4.5,
          }}
        >
          View details
        </Button>
        {/* </Box> */}
      </Stack>
    </Container>
  );
};
export default CardWithTextAndCountersSection;
