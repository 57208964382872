import { cloneElement, isValidElement } from "react"
import SimpleElement from "./SimpleElement"
import StepperElement from "./StepperElement"
import UpperLabeledTabsElement from "./UpperLabeledTabsElement"

const specToElementMapper = {
    Simple: <SimpleElement />,
    UpperLabeledTabs: <UpperLabeledTabsElement />,
    Stepper: <StepperElement />
}

const getDashboardElement = (spec) => {
    const element = specToElementMapper[spec.type]
    return isValidElement(element) ? cloneElement(element, spec) : element
}

export default getDashboardElement