const he = {
    signIn: "כניסה לחשבון",
    optional: "בחירה",
    userSettings: "הגדרות משתמש",
    logout: "יציאה",
    account: "החשבון",
    security: "אבטחה",
    firstName: "שם פרטי",
    lastName: "שם משפחה",
    emailAddress: "דוא״ל",
    password: "סיסמה",
    phone: "מספר טלפון",
    address: "כתובת",
    save: "שמירה",
    dangerZone: "אזור מסוכן",
    dangerZoneDeleteAccountText: "לחץ על הכפתור למטה למחיקת החשבון",
    languages: "השפות",
    notifications: "ההתראות",
    noNewNotifications: "אין התראות חדשות",
    getResetLinkAfterButtonClick: "לחץ על הכפתור למטה כדי לקבל קישור לשחזור סיסמה להדוא״ל שמקושר לחשבונך",
    getResetLink: "קבל קישור לשחזור סיסמה",
    passwordReset: "שחזור סיסמה",
    simpleElementText: "בוא נתחיל לבנות משהוא חדש ומקסים בשימוש התשתית של מייקרוספיק",
    pleaseWait: "המתן בבקשה",
    deleteAccount: "מחיקת חשבון",
    cancel: "ביטול",
    confirm: "אישור",
    emailOrPasswordInvalid: "הדוא״ל או הסיסמה שגויים.",
    rememberMe: "תזכור אותי",
    forgotPassword: "שכחת סיסמה?",
    newAccount: "חשבון חדש?",
    signUp: "תרשום אותי",
    resetLinkSuccessfullySent: "קישור לשחזור סיסמה נשלח בהצלחה למייל שלך.",
    errorTryLater: "אירעה שגיאה, בבקשה תנסה מאוחר יותר",
    new: "חדש",
    newUnreadNotifications: "יש לך {0} התראות שלא נקראו",
    deleteAccountConfirmationDialogText: "נא לאשר מחיקת החשבון",
    notificationsWaitMessage: "המתן בבקשה עד סיום הפעולה",
    viewAll: "הצג הכל"
}

const getHebrewMap = () => {
    return he
}

export default getHebrewMap