import { LockOutlined } from "@mui/icons-material";
import { Alert, Avatar, Box, Button, Container, CssBaseline, Grid, Link, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useMicrospecDynamicUIContext } from "../GlobalProvider";
import Copyrights from "../Components/Copyrights";
import { strengthColor, strengthIndicator } from "../Helpers/PasswordStrength";
import AuthWrapper from "./AuthWrapper";
import { useMicrospecThemeLanguageContext } from "../Themes";

const Register = () => {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo')
  const { localizationProvider } = useMicrospecThemeLanguageContext()
  const { cloudProvider } = useMicrospecDynamicUIContext()
  const [, setPassLen] = useState(0)
  const [passwordMessage, setPasswordMessage] = useState(false)
  const [passIndicator, setPassIndicator] = useState(strengthColor(strengthIndicator("")))
  const [error, setError] = useState(false)
  const { signInWithEmailAndPassword, createUserWithEmailAndPassword } = cloudProvider

  const handlePasswordChange = (event) => {
    setError(false)
    const value = event.target.value
    const pi = strengthColor(strengthIndicator(value))
    setPassLen(value.length)
    setPassIndicator(pi)
    setPasswordMessage(value.length !== 0)
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(false)
    setPasswordMessage(false)
    const data = new FormData(event.currentTarget);
    const firstName = data.get('firstName')
    const lastName = data.get('lastName')
    const email = data.get('email')
    const password = data.get('password')
    const result = await createUserWithEmailAndPassword({ firstName, lastName, email, password })
    await loginOnSuccessfulRegistration(result, email, password)
    setError(!result)
  };

  const loginOnSuccessfulRegistration = async (result, email, password) => {
    if (result) {
        const signInResult = await signInWithEmailAndPassword(email, password)
        if (signInResult) {
          window.location.reload()
        } else {
          window.location.replace(redirectTo ? "/login?redirectTo=" + redirectTo : "/login")
        }
    }
  }

  return (
    <AuthWrapper redirectTo={redirectTo}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar sx={{ m: 1, }}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="firstName"
              name="firstName"
              label={localizationProvider.translateByType({
                type: "Internal",
                text: "firstName"
              })}
              autoComplete="firstName"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="lastName"
              name="lastName"
              autoComplete="lastName"
              label={localizationProvider.translateByType({
                type: "Internal",
                text: "lastName"
              })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={localizationProvider.translateByType({
                type: "Internal",
                text: "emailAddress"
              })}
              name="email"
              autoComplete="email"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={localizationProvider.translateByType({
                type: "Internal",
                text: "password"
              })}
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handlePasswordChange}
              sx={{
                borderColor: passIndicator.color
              }}
            />
            {error && <Alert severity='error'>
              Something went wrong, maybe email is already registered or password is not meeting security criteria, please try again later.
            </Alert>}
            {passwordMessage && <Alert severity={passIndicator.color}>Password is {passIndicator.label}</Alert>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {localizationProvider.translateByType({
                type: "Internal",
                text: "signUp"
              })}
            </Button>
            <Grid container>
              <Grid item>
                <Link href={redirectTo ? "/login?redirectTo=" + redirectTo : "/login"} variant="body2">
                  <Typography>{localizationProvider.translateByType({
                    type: "Internal",
                    text: "signIn"
                  })}</Typography>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyrights sx={{ mt: 8, mb: 4 }} />
      </Container>
    </AuthWrapper>
  );
};

export default Register;
