import { Link, Typography } from "@mui/material";

const Copyrights = (props) => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            Copyright © <Link color="inherit" href="http://microspec.dev">Microspective Dev</Link> {new Date().getFullYear()}
        </Typography>
    );
}

export default Copyrights