import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { useMicrospecThemeLanguageContext } from '../Themes';

export default function ConfirmationDialog({ outterButtonText, dialogTitle, dialogContent, dialogButtonText, onDialogButtonClick, buttonsColor }) {
    const { localizationProvider } = useMicrospecThemeLanguageContext()
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <Button
                onClick={handleClickOpen()}
                variant="contained"
                color={buttonsColor}
            >{outterButtonText}</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
            >
                <DialogTitle variant='h3' id="scroll-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        {dialogContent}
                    </DialogContentText>
                    {/* <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        In order to proceed with the confirmation, please enter the two words in the input field.
                    </DialogContentText> */}
                </DialogContent>
                <DialogActions>
                    <Button sx={{ mx: 0.5 }} onClick={handleClose} variant="contained">{localizationProvider.translateByType({
                        type: "Internal",
                        text: "cancel"
                    })}</Button>
                    <Button sx={{ mx: 0.5 }} color={buttonsColor} variant="outlined" onClick={() => {
                        onDialogButtonClick()
                        handleClose()
                    }}>{dialogButtonText}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}