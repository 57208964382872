import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Container,
  Divider,
  Grid,
  List,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { Fragment, useState } from "react";
import { getOrDefault, verifyNonEmpty, verifyNonNull } from "../../../Helpers/Utils";

const CollapsableFaqWithFloatingPhoto = ({ spec, type, index }) => {
  const theme = useTheme();
  const mainPrimaryColor = theme.palette.primary.main
  const [openedQuestion, setOpenedQuestion] = useState(0);

  const mainTitle = getOrDefault(spec.mainTitle, {})
  const mainTitleText = mainTitle.text
  verifyNonNull(mainTitleText, `Main title in ${type} in index ${index}`)
  const mainTitleColor = getOrDefault(mainTitle.color, mainPrimaryColor)
  
  const questions = spec.questions
  verifyNonNull(questions, `Question in ${type} in index ${index}`)
  verifyNonEmpty(questions)
  
  const handleOpenQuestionCollapse = (indexToOpen) => {
    if (openedQuestion === indexToOpen) {
      setOpenedQuestion();
    } else {
      setOpenedQuestion(indexToOpen);
    }
  };

  return (
    <Box
      sx={{ backgroundColor: theme.palette.primary.main, width: "100%", py: 4, position: "relative" }}
      id="box-that-can-be-deleted-after-only-here-for-bg"
    >
      <Container maxWidth="lg">
        <Card elevation={0} sx={{ borderRadius: 3 }}>
          <CardContent sx={{ p: 4 }}>
            <Grid
              container
              spacing={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item xs={12} md={4}>
                <img
                  alt="faq"
                  src="https://r3dxm.github.io/faq-accordion-card/static/media/illustration-woman-online-desktop.843eb114c7213a596ee246bf87f27008.svg"
                  style={{
                    width: "100%",
                    maxWidth: 500,
                    filter: `drop-shadow(25px 20px 20px grey)`,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8} sx={{ position: "relative" }}>
                <Typography variant="h1"
                  sx={{
                    color: mainTitleColor,
                    letterSpacing: "0.5rem",
                    textAlign: "center",
                  }}>
                  {mainTitleText}
                </Typography>
            </Grid>
          </Grid>
          <List>
            {questions.map((question, i) => (
              <Fragment key={`${i}-faq-fragment`}>
                <Card
                  sx={{
                    "&:hover": {
                      boxShadow: theme.shadows[4],
                    },
                    my: 2,
                    zIndex: 2,
                    position: "relative",
                    borderRadius: 2,
                  }}
                  elevation={2}
                >
                  <CardHeader
                    title={`${openedQuestion === i ? "-" : "+"} ${question.title}`}
                    titleTypographyProps={{
                      textAlign: "start",
                      style: {fontSize: 17}
                    }}
                    sx={{
                      cursor: "pointer",
                      borderLeft:
                        openedQuestion === i
                          ? "2px solid blue"
                          : "2px solid grey",
                      backgroundColor:
                        openedQuestion === i &&
                        alpha(theme.palette.info.light, 0.085),

                      "&:hover": {
                        backgroundColor:
                          openedQuestion !== i && theme.palette.action.hover,
                          boxShadow: theme.shadows[4],
                      },
                    }}
                    onClick={() => handleOpenQuestionCollapse(i)}
                  />
                  {openedQuestion === i && <Divider />}
                  <Collapse in={openedQuestion === i}>
                    <CardContent>
                      <Typography
                        sx={{ textAlign: "start", px: 2, mt: 2 }}
                        color="text.secondary"
                      >
                        {question.answer}
                      </Typography>
                    </CardContent>
                  </Collapse>
                </Card>
              </Fragment>
            ))}
          </List>
        </CardContent>
      </Card>
    </Container>
    </Box >
  );
};
export default CollapsableFaqWithFloatingPhoto;
