import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import * as React from 'react';
import CustomizedStepper from './CustomizedStepper';

const StepperElement = (spec) => {
    const stepperObjects = spec.object
    const { finalStep, showReset } = spec
    const [commonProperties, setCommonProperties] = React.useState({})
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const optionalStepsIndices = stepperObjects.reduce((optionals, currentValue, currentIndex) => {
        if (currentValue.optional) {
            optionals.push(currentIndex)
        }
        return optionals;
    }, [])
    const addToCommonProperties = (propertiesToAdd) => {
        const tempProps = commonProperties
        for (const key in propertiesToAdd) {
            tempProps[key] = propertiesToAdd[key]
        }
        setCommonProperties(tempProps)
    }

    const isStepOptional = (step) => {
        return optionalStepsIndices.includes(step);
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    return (
        <Box sx={{ width: '100%' }} marginTop={2}>
            <CustomizedStepper props={{ activeStep, stepperObjects, isStepOptional, isStepSkipped }} />
            <Divider sx={{ mt: 2 }} />
            {
                activeStep !== stepperObjects.length ?
                    (
                        <React.Fragment>
                            <Box marginTop={2} marginRight={2} marginLeft={2} >
                                {
                                    React.isValidElement(stepperObjects[activeStep].element) ?
                                        React.cloneElement(stepperObjects[activeStep].element, { commonProperties, addToCommonProperties }) :
                                        stepperObjects[activeStep].element
                                }
                            </Box>
                            <Divider sx={{ mt: 2, mx: 0 }} />
                            <Box marginTop={2} marginRight={2} marginLeft={2} >
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        Back
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    {isStepOptional(activeStep) && (
                                        <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                            Skip
                                        </Button>
                                    )}
                                    <Button onClick={
                                        activeStep === stepperObjects.length - 1 &&
                                            finalStep.onClick != null ?
                                            (event) => {
                                                finalStep.onClick(event)
                                                handleNext()
                                            }
                                            :
                                            handleNext
                                    }>
                                        {activeStep === stepperObjects.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </Box>
                            </Box>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Box marginTop={2} marginRight={2} marginLeft={2} >
                                {
                                    React.isValidElement(finalStep.element) ?
                                        React.cloneElement(finalStep.element, { commonProperties, addToCommonProperties }) :
                                        finalStep.element
                                }
                                <Divider sx={{ mt: 2 }} />
                                {
                                    showReset && <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button onClick={(event) => {
                                            window.location.reload();
                                        }}>Reset</Button>
                                    </Box>
                                }
                            </Box>
                        </React.Fragment>
                    )}
        </Box>
    );
}

export default StepperElement