import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import mainBg from "../images/banner-bg.png";

function MainHeader() {
  return (
    <Grid
      container
      bgcolor={"#f2f2f2"}
      sx={{
        height: "100vh",
        width: "100vw",
        overflowX: {
          xs: "hidden",
          md: "initial",
        },
      }}
      position={"relative"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        container
        spacing={1}
      >
        <Grid item xs={12}>
          <Typography
            variant={"h3"}
            sx={{
              backgroundColor: "#146eb4",
              color: "#fff",
              padding: "15px 20px",
              borderRadius: "40px",
              width: "70%",
              textAlign: "center",
            }}
            mx={"auto"}
          >
            הצטרף היום לעסקים מוצלחים ותהנה מהשירות
          </Typography>
          <Typography
            variant="h1"
            sx={{
              color: "#232f3e",
              fontSize: "2.5rem",
              fontWeight: "bold",
              padding: "20px 0",
              width: "70%",
              // textAlign: "center",
            }}
            mx={"auto"}
          >
            פלו לוגיסטי - פתרונות עסקיים ולוגיסטיקה
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#232f3e",
              fontSize: "1rem",
              padding: "20px 0",
              width: "70%",
              // textAlign: "center",
            }}
            mx={"auto"}
          >
            פלו לוגיסטי מספקת פתרונות עסקיים ולוגיסטיים מתקדמים, המותאמים לצרכים הייחודיים של כל לקוח. אנו מתחייבים לשירות מקצועי, אמין ויעיל, המבטיח את הצלחת העסק שלך.
          </Typography>
          <Grid
            item
            display={"flex"}
            gap={2}
            sx={{
              width: "70%",
            }}
            mx={"auto"}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                window.location.href = "/business";
              }}
            >
              כניסה
            </Button>
            <Button variant="outlined" color="secondary">
              צרו קשר
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        sm={4}
        md={6}
        sx={{
          display: { xs: "none", sm: "flex" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          sx={{
            width: { xs: "80%", md: "90%" },
            position: {
              xs: "absolute",
              md: "initial",
            },
            right: {
              xs: "-45%",
            },
          }}
          alt="Main Background"
          src={mainBg}
        />
      </Grid>
    </Grid>
  );
}

export default MainHeader;
