import getArabicMap from "./ar"
import getEnglishMap from "./en"
import getHebrewMap from "./he"

const languageMap = {
    ar: getArabicMap,
    he: getHebrewMap,
    en: getEnglishMap
}

const getLanguageProvider = (language) => {
    const languageItem = languageMap[language]
    if (languageItem) {
        return languageItem()
    }
    throw new Error(`Language ${language} is not yet supported by Microspec Dashboard Builder.`)
}

export default getLanguageProvider