import {
    Avatar, Grid, Toolbar,
    Typography
} from "@mui/material";
import { useMicrospecThemeLanguageContext } from "../../Themes";
const DrawerUpperContent = ({ data }) => {
    const { application, dashboard } = data
    const { localizationProvider } = useMicrospecThemeLanguageContext()
    return (
        <Toolbar>
            <Grid
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1.5,
                }}
            >
                <Avatar
                    src={application.logo}
                />
                <Grid
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        mx: 0.5
                    }}>
                    <Typography
                        variant="h4"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 1,
                            display: { md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 1000,
                            letterSpacing: '.1rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            overflow: 'auto'
                        }}
                    ><strong>{localizationProvider.translateByType({
                        type: 'Localized',
                        text: 'application.name'
                    })}</strong></Typography>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 1,
                            display: { md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 300,
                            letterSpacing: '.1rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            overflow: 'auto'
                        }}
                    >
                        {localizationProvider.translateByType(dashboard.name)}
                    </Typography>
                </Grid>
            </Grid>
        </Toolbar>
    )
}

export default DrawerUpperContent