import {
    Divider
} from "@mui/material";
import DrawerCommonContent from "./DrawerCommonContent";
import DrawerSpecificContent from "./DrawerSpecificContent";
import DrawerUpperContent from "./DrawerUpperContent";

const DrawerContent = ({ data }) => {
    return (
        <>
            <DrawerUpperContent data={data} />
            <Divider />
            <DrawerSpecificContent data={data} />
            <Divider />
            <DrawerCommonContent data={data}/>
        </>
    );
};
//
export default DrawerContent;