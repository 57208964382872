import {
    Drawer
} from "@mui/material";
import { getRightOrLeft } from "../../Helpers/Utils";
import { drawerWidth } from "../Dashboard";
import DrawerContent from "./DrawerContent";

const DashboardDrawer = ({ data }) => {
    const { handleDrawerToggle, mobileOpen } = data;
    return (
        <Drawer
            container={window.document.body}
            anchor={getRightOrLeft()}
            slot="nav"
            variant={mobileOpen ? "temporary" : "permanent"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: mobileOpen,
            }}
            sx={{
                width: { sm: drawerWidth }, flexShrink: { sm: 0 },
                display: {
                    xs: mobileOpen ? "block" : "none",
                    sm: mobileOpen ? "none" : "block",
                },
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                },
            }}
        >
            <DrawerContent data={data} />
        </Drawer>
    );
};

export default DashboardDrawer;
