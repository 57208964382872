import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useMicrospecThemeLanguageContext } from "../../../Themes";

const RoundedImageAndTextSquareAside = () => {
  const theme = useTheme();
  const matchMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const {localizationProvider} = useMicrospecThemeLanguageContext();
  const quoteData = {
    image:
      "https://images.pexels.com/photos/5722164/pexels-photo-5722164.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    quote:
      "“ באחים מוצאים כוח, תמיכה ושיתוף פעולה. אנחנו עמוקות מאמינים בחוזק הקהילה ובאומץ האחים לעמוד זה לזה בכל עת ובכל מצב ”",
    author: "מוראד סייד אחמד",
    position: 'מנכ"ל איגוד האחים',
  };
  return (
    <Grid
      sx={{
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "70vh",
        p: 6,
      }}
      container
      spacing={0}
      component="section"
    >
      <Grid item xs={12} md={7}>
        <Box
          sx={{
            backgroundImage: `url(${quoteData.image})`,
            backgroundPosition: "center",
            backgroundSize: "100%",
            width: "100%",
            height: 550,
            borderRadius: 2,
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          backgroundColor: "#1F242E",
          borderRadius: 2,
          height: "100%",
          maxWidth: !matchMdDown ? "400px !important" : "100%",
          mx: matchMdDown && 4,
          transform: matchMdDown ? "translateY(-38px)" : localizationProvider.dir === 'rtl' ? "translateX(30px)" : "translateX(-30px)",
        }}
      >
        <Stack sx={{ justifyContent: "space-between", p: 2, pb: 6 }}>
          <Typography
            sx={{
              mb: !matchMdDown && 10,
              fontWeight: 200,
              fontSize: 28,
              color: theme.palette.primary.contrastText,
              lineHeight: 2.25,
            }}
          >
            {quoteData.quote}
          </Typography>
          <Box sx={{ width: "100%", px: 3 }}>
            <Typography
              fontWeight={700}
              fontSize={13}
              sx={{
                "background-image":
                  "-webkit-radial-gradient(10% 20%, circle farthest-corner, #fdc168 0%, #fb8080 90%)",
                // "background-image":
                //   "-o-radial-gradient(10% 20%, circle farthest-corner, #fdc168 0%, #fb8080 90%)",
                // "background-image":
                //   "radial-gradient(circle farthest-corner at 10% 20%, #fdc168 0%, #fb8080 90%)",
                "-webkit-background-clip": "text",
                "-webkit-text-fill-color": "transparent",
                my: 2,
                textAlign: "start",
                // wordSpacing: "1rem",
                letterSpacing: "0.2rem",
              }}
            >
              {quoteData.author}
            </Typography>
            <Typography
              sx={{
                textAlign: "start",
                color: theme.palette.primary.contrastText,
              }}
            >
              {quoteData.position}
            </Typography>
          </Box>
        </Stack>
      </Grid>
      <Box
        sx={{
          position: "absolute",
          width: 400,
          height: 400,
          border: "70px solid #f7f7f7",
          borderRadius: "50%",
          zIndex: -1,
          left: 0,
          bottom: -10,
        }}
      />
    </Grid>
  );
};
export default RoundedImageAndTextSquareAside;
