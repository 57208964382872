import { Box, Container } from '@mui/material';
import AccountDetails from '../AccountDetails';
import AccountDetailsForm from './AccountDetailsForm';
import DeleteAccountSection from './DeleteAccountSection';

const AccountTab = () => {
  return (
    <Box
      sx={{
        minHeight: '100%',
        py: 3
      }}>
      <Container maxWidth="lg">
        <AccountDetails />
        <AccountDetailsForm />
        <DeleteAccountSection />
      </Container>
    </Box>
  );
};
export default AccountTab;
