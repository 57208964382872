import { SettingsOutlined } from '@mui/icons-material';
import * as React from 'react';
import AccountTab from './Account/AccountTab';
import SecurityTab from './Security/SecurityTab';

const addUserSettings = (itemsList) => {
    itemsList.push({
        path: "settings",
        name: {
            type: "Internal",
            text: "userSettings"
        },
        icon: <SettingsOutlined />,
        spec: {
            type: "UpperLabeledTabs",
            object: [
                {
                    name: {
                        type: "Internal",
                        text: "account"
                    },
                    element: <AccountTab />,
                },
                {
                    name: {
                        type: "Internal",
                        text: "security"
                    },
                    element: <SecurityTab />,
                }
            ]
        },
        common: true
    })
}

export { addUserSettings };
