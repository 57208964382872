import React from "react";
import MicrospecDynamicUI from "../MicrospecDynamicUI";
import config from "./config";
const App = () => {
  return (
    <MicrospecDynamicUI config={ config } />
  );
};

export default App;
