import { cloneElement, isValidElement } from "react"
import SimpleElement from "../../Dashboard/ElementMapper/SimpleElement"
import CompositeSectionsElement from "./CompositeSectionsElement"
import NotFoundElement from "./NotFoundElement"

const specToElementMapper = {
    Simple: <SimpleElement />,
    NotFound: <NotFoundElement />,
    CompositeSections: <CompositeSectionsElement />,
}

const getPagesElement = (spec) => {
    const element = specToElementMapper[spec.type]
    const processed = isValidElement(element) ? cloneElement(element, spec) : element
    return processed
}

export default getPagesElement