import { Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import { drawerWidth } from "./Dashboard";

const DashboardContent = ({ data }) => {
  const { item } = data;
  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
    >
      <Toolbar />
      {item.element}
    </Box>
  );
};

export default DashboardContent;
