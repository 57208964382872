import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField
} from '@mui/material';
import { useState } from 'react';
import { useMicrospecDynamicUIContext } from "../../../../../GlobalProvider";
import { useMicrospecThemeLanguageContext } from '../../../../../Themes';
const AccountDetailsForm = () => {
    const { cloudProvider } = useMicrospecDynamicUIContext()
    const { localizationProvider } = useMicrospecThemeLanguageContext()
    const { user, userProfile } = cloudProvider

    const attributes = userProfile.attributes
    const [firstName, setFirstName] = useState(attributes.firstName)
    const [lastName, setLastName] = useState(attributes.lastName)

    const email = user.email
    const [phone, setPhone] = useState("")
    const [address, setAddress] = useState("")

    const handleSubmit = (event) => {
        event.preventDefault()
        userProfile.methods.updateUserProfile(firstName, lastName)
    };

    return (
        <Grid item lg={8} md={6} xs={12} sx={{ marginBottom: 2 }}>
            <Card component="form" onSubmit={handleSubmit}>
                <CardHeader title={localizationProvider.translateByType({
                    type: "Internal",
                    text: "account"
                })} />
                <Divider />
                <CardContent >
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label={localizationProvider.translateByType({
                                    type: "Internal",
                                    text: "firstName"
                                })}
                                id="firstName"
                                name="firstName"
                                required
                                value={firstName}
                                variant="outlined"
                                onChange={(event) => setFirstName(event.target.value)}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label={localizationProvider.translateByType({
                                    type: "Internal",
                                    text: "lastName"
                                })}
                                id="lastName"
                                name="lastName"
                                required
                                value={lastName}
                                variant="outlined"
                                onChange={(event) => setLastName(event.target.value)}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label={localizationProvider.translateByType({
                                    type: "Internal",
                                    text: "emailAddress"
                                })}
                                id="email"
                                name="email"
                                autoComplete="email"
                                disabled
                                value={email}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label={localizationProvider.translateByType({
                                    type: "Internal",
                                    text: "phone"
                                })}
                                id="phone"
                                name="phone"
                                value={phone}
                                variant="outlined"
                                onChange={(event) => setPhone(event.target.value)}
                                disabled
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label={localizationProvider.translateByType({
                                    type: "Internal",
                                    text: "address"
                                })}
                                id="address"
                                name="address"
                                // required
                                value={address}
                                variant="outlined"
                                onChange={(event) => setAddress(event.target.value)}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        p: 2
                    }}
                >
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                    >
                        {localizationProvider.translateByType({
                            type: "Internal",
                            text: "save"
                        })}
                    </Button>
                </Box>
            </Card>
        </Grid>
    );
};

export default AccountDetailsForm;
