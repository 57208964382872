import { Box, Container } from '@mui/material';
import AccountDetails from '../AccountDetails';
import PasswordResetSection from './PasswordResetSection';

const SecurityTab = () => {
  return (
    <Box
      sx={{
        minHeight: '100%',
        py: 3
      }}>
      <Container maxWidth="lg">
        <AccountDetails />
        <PasswordResetSection />
      </Container>
    </Box>
  );
};
export default SecurityTab;
