import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  Typography,
  alpha,
  useTheme
} from "@mui/material";
import screenBg from "./screenBg.svg";

const NewsLetterCard = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{ backgroundColor: "#242742", width: "100%", py: 4 }}
      id="box-that-can-be-deleted-after-only-here-for-bg"
    >
      <Container maxWidth="md">
        <Card elevation={16} sx={{ borderRadius: 3 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={12} md={5}>
                <Box
                  sx={{
                    backgroundImage: `url(${screenBg})`,
                    position: "static",
                    order: 1,
                    borderRadius: "2rem",
                    backgroundSize: "cover",
                    height: "100%",
                  }}
                />
              </Grid>
              <Grid item sm={12} md={7}>
                <Stack sx={{ mt: 2 }}>
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: "5rem",
                      textAlign: "right",
                      color: "#242742",
                    }}
                  >
                    תשארו מעודכנים!
                  </Typography>
                  <Typography sx={{ textAlign: "right" }} variant="body1">
                    הצטרפו ל+2,000 אנשים שרשומים לניוזלטר שלנו
                  </Typography>
                  <List dense>
                    {[1, 2, 3].map((_) => (
                      <ListItem key={_}>
                        <ListItemText
                          primaryTypographyProps={{
                            sx: {
                              textAlign: "right",
                            },
                          }}
                          primary="פריט אחד"
                        />
                        <ListItemAvatar>
                          <Checkbox color="error" checked />
                        </ListItemAvatar>
                      </ListItem>
                    ))}
                  </List>
                  <Stack
                    sx={{
                      mt: 2,
                      gap: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      flex: 1,
                      //   mx: "auto",
                    }}
                  >
                    <FormControl
                      fullWidth
                      sx={{ direction: "rtl", maxWidth: 400 }}
                    >
                      <FormLabel
                        sx={{
                          textAlign: "right",
                          fontWeight: 600,
                          fontSize: 13,
                        }}
                        htmlFor="email-text-field"
                      >
                        Name
                      </FormLabel>
                      <TextField
                        fullWidth
                        //   sx={{ borderRadius: 3 }}
                        id="email-text-field"
                        variant="outlined"
                        placeholder="email@company.com"
                      />
                    </FormControl>
                    <Button
                      sx={{
                        flex: 1,
                        display: "flex",
                        backgroundColor: "#242742",
                        color: theme.palette.primary.contrastText,
                        letterSpacing: "0.1rem",
                        fontSize: 18,
                        maxWidth: 400,
                        fontWeight: 600,
                        "&:hover": {
                          boxShadow: theme.shadows[6],
                          backgroundColor: alpha("rgb(255, 94, 103)", 0.85),
                          transition: "all 0.5s",
                        },
                      }}
                      variant="contained"
                      fullWidth
                    >
                      הרשמה
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};
export default NewsLetterCard;
