import { LanguageOutlined } from "@mui/icons-material"
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material"
import { useState } from "react"
import { useMicrospecDynamicUIContext } from "../GlobalProvider"
import { handleLanguageChange, useMicrospecThemeLanguageContext } from "../Themes"

const LanguagesMenu = () => {
    const { config } = useMicrospecDynamicUIContext()
    const { localizationProvider } = useMicrospecThemeLanguageContext()
    const { languages } = config

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguagePick = (event) => {
        const language = event.currentTarget.id
        handleLanguageChange(language)
    }

    return (
        <>
            <Tooltip title={localizationProvider.translateByType({
                type: "Internal",
                text: "languages"
            })}>
                <IconButton
                    id="languages"
                    size="large"
                    aria-label="languages"
                    color="inherit"
                    onClick={handleClick}
                >
                    <LanguageOutlined />
                </IconButton>
            </Tooltip>
            <Menu
                id="languages-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {
                    languages.list.map((value) => {
                        return <MenuItem id={value.code} key={value.code} name={value.code} itemID={value.code} onClick={handleLanguagePick}>{value.name}</MenuItem>
                    })
                }
            </Menu>
        </>
    )
}

export default LanguagesMenu