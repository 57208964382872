import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  lighten,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import bg from "./bg.avif";

const HeroSectionWithScrollTrigger = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const trigger = useScrollTrigger({
    disableHysteresis: false,
    threshold: 0,
    target: undefined,
  });
  const [offsetTop, setOffsetTop] = useState(0);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      const scrollTarget = document.getElementById("scrollTarget");
      if (scrollTarget) {
        setOffsetTop(window.scrollY);
      }
    });
  }, [trigger]);

  const linesDistanceFromScreen = 12;
  const dividerColor = theme.palette.common.white;

  return (
    <Paper
      sx={{
        overflowX: "unset",
        // maxWidth: "100vw",
        display: "block",
      }}
    >
      <Box id="scrollTarget">
        <Box
          sx={{
            position: "relative",
            height: "100vh !important",
            //   width: "100vw",
            overflow: "hidden",
            right: 0,
            top: 0,
          }}
        >
          <img
            alt="background with zooming effect"
            style={{
              width: "100%",
              display: "block",
              height: "100%",
              objectFit: "cover",
              filter:
                offsetTop &&
                trigger &&
                `blur(${offsetTop * 0.15 < 30 ? offsetTop * 0.15 : 30}px)`,
              transform:
                offsetTop &&
                trigger &&
                `scale(${
                  offsetTop * 0.01 < 1
                    ? 1
                    : offsetTop * 0.01 > 2.5
                    ? 1.5
                    : offsetTop * 0.01
                })`,
              transition: "all 0.6s",
            }}
            src={bg}
          />
          <Stack
            sx={{
              height: "100%",
              width: "100%",
              position: "absolute",
              zIndex: 10,
              top: 0,
              right: 0,
              left: 0,
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Stack
              sx={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                px: linesDistanceFromScreen + 1,
                zIndex: 2,
              }}
              gap={2}
            >
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ fontSize: "1.2rem", textAlign: "start" }}
              >
                Join us today!
              </Typography>
              <Typography
                variant="h1"
                sx={{
                  fontSize: matchDownMd ? "2rem" : "3rem",
                  color: "#66558b",
                  textAlign: "start",
                  maxWidth: "620px",
                  width: "50vw",
                }}
              >
                And get immediate offers and discounts with our association
              </Typography>
              <Button
                sx={{
                  color: "wheat",
                  borderRadius: 4,
                  backgroundColor: "tomato",
                  maxWidth: 220,
                  fontSize: "1rem",
                  "&:hover": {
                    backgroundColor: lighten(theme.palette.error.light, 0.01),
                    // color: "#66558b",
                  },
                }}
                fullWidth
              >
                Register
              </Button>
            </Stack>
            <Box
              key="vertical-lines-box"
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0)",
                position: "absolute",
                zIndex: 0,
                mx: "auto",
                "&:before": {
                  content: '""',
                  width: "100%",
                  height: "100%",
                  borderLeft: `2px dashed ${dividerColor}`,
                  position: "absolute",
                  left: linesDistanceFromScreen * 8,
                },
                "&:after": {
                  content: '""',
                  width: "1px",
                  height: "100%",
                  borderRight: `2px dashed ${dividerColor}`,
                  position: "absolute",
                  right: linesDistanceFromScreen * 8,
                },
              }}
            ></Box>
            <Box
              key="horizontal-lines-box"
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0)",
                position: "absolute",
                zIndex: 1,
                mx: "auto",
                "&:before": {
                  content: '""',
                  width: "100%",
                  height: "1px",
                  borderBottom: `2px dashed ${dividerColor}`,
                  position: "absolute",
                  bottom: "50%",
                  left: `calc(100% - ${linesDistanceFromScreen * 8}px)`,
                },
                "&:after": {
                  content: '""',
                  height: "1px",
                  width: "100%",
                  borderTop: `2px dashed ${dividerColor}`,
                  position: "absolute",
                  bottom: "50%",
                  right: `calc(100% - ${linesDistanceFromScreen * 8}px)`,
                },
              }}
            >
              <Stack
                sx={{
                  width: "100%",
                  height: 110,
                  position: "absolute",
                  bottom: 0,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ color: "#66558b" }}>Scroll</Typography>
                <Stack
                  sx={{
                    width: 64,
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    height: "fit-content",
                  }}
                >
                  <Box
                    sx={{
                      width: 32,
                      height: 32,
                      borderRadius: "50%",
                      border: `1px dashed ${dividerColor}`,
                      transform: "translateY(16px)",
                    }}
                  />
                  <Box
                    sx={{
                      height: "calc(100% - 32px)",
                      width: 2,
                      borderRight: `1px solid ${dividerColor}`,
                      position: "relative",
                      "&:before": {
                        content: '""',
                        height: linesDistanceFromScreen * 0.3,
                        width: linesDistanceFromScreen * 0.3,
                        backgroundColor: dividerColor,
                        borderRadius: "50%",
                        position: "absolute",
                        top: 0,
                        left: `calc(50% - ${linesDistanceFromScreen * 0.05}px)`,
                      },
                    }}
                  />
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Box>
        {/* <Box
          sx={{
            height: "70vh",
            width: "100vw",
            position: "relative",
            right: 0,
            backgroundColor: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography color="white">just to scroll see the effect</Typography>
        </Box> */}
      </Box>
    </Paper>
  );
};
export default HeroSectionWithScrollTrigger;
