import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  List,
  Typography,
  darken,
  lighten,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useId } from "react";
import pastelBg from "./pastelbg.jpg";
import "./stackingSections.css";

const StackingCards = () => {
  const theme = useTheme();
  const matchMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const cards = [
    {
      title: "Work",
      id: useId(),
      bg: "#fece32",
      description:
        "Get updated regarding everything related to your field of interest",
      features: [
        { description: "Stay across many company news, shoutouts and events" },
        { description: "Completee trainging and track goals" },
        { description: "Manage leave requests and timesheets" },
      ],
      imgSection: (
        <img
          style={{
            width: "100%",
            height: "100%",
          }}
          alt="first"
          src="https://swagapp.com/wp-content/uploads/2023/02/work-desktop-2.png"
        />
      ),
    },
    {
      title: "Career",
      id: useId(),
      bg: "#ffaf77",
      description: "Discover new opportunities and roles",
      features: [
        { description: "Discover internal job opportunities" },
        { description: "Fast-track your career development" },
        { description: "Refers friends for roles" },
      ],
      imgSection: (
        <img
          style={{
            width: "100%",
            height: "100%",
          }}
          alt="first"
          src="https://swagapp.com/wp-content/uploads/2023/02/career-desktop-01-624x473.webp"
        />
      ),
    },
    {
      title: "Knowledge",
      id: useId(),
      bg: "#92abfc",
      description:
        "Share your knowledge and get to know more people that will value it",
      features: [
        {
          description: "Grow your knowledge just by being a member",
        },
        { description: "Save on every event and activity" },
      ],
      imgSection: (
        <img
          style={{
            width: "100%",
            height: "100%",
          }}
          alt="first"
          src="https://swagapp.com/wp-content/uploads/2023/03/work-desktop-01.webp"
        />
      ),
    },
    {
      title: "Benifits",
      id: useId(),
      bg: "#ba80e6",
      description:
        "Joining us will return lots of benifits and copouns just for being a member",
      features: [
        {
          description:
            "Enjoy copouns and discounts in many businesses around the country",
        },
        {
          description: "Score your own diamonds",
        },
        {
          description: "Use your diamonds for knowledge share",
        },
      ],
      imgSection: (
        <img
          style={{
            width: "100%",
            height: "100%",
            transform: matchMdDown && "translateY(-50px)",
          }}
          alt="first"
          src="https://swagapp.com/wp-content/uploads/2023/04/swag-benefits-1.png"
        />
      ),
    },
    {
      title: "Events",
      id: useId(),
      bg: "#fcc",
      description:
        "Get the infinite chances for learning new stuff and building your own network connections",
      features: [
        {
          description: "Get special invite for each event",
        },
        {
          description: "Meet new people and build your own connections network",
        },
      ],
      imgSection: (
        <img
          style={{
            width: "100%",
            height: "100%",
          }}
          alt="first"
          src="https://swagapp.com/wp-content/uploads/2023/02/work-desktop-2.png"
        />
      ),
    },
  ];
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
      }}
    >
      <List
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: `repeat(${cards.length}, 87vh)`,
          gap: "4vw",
          paddingBottom: `calc(${cards.length} * 1.5em)`,
          marginBottom: "4vw",
          maxWidth: "1000px",
          margin: "0 auto",
          paddingTop: "4em",
        }}
      >
        {cards.map((cardDetails, i) => (
          <Box
            sx={{
              paddingTop: `calc(${i} * 1.5em)`,
              position: "sticky",
              zIndex: i + 1,
              top: 0,
              // width: "100%",
              px: 4,
            }}
          >
            <Card
              sx={{
                borderRadius: 8,
                backgroundColor: "transparent",
                border: "unset !important",
              }}
              elevation={0}
              key={cardDetails.id}
            >
              {/* <Box
                sx={{
                  backgroundColor: `${darken(cardDetails.bg, 0.05)}`,
                  width: 150,
                  height: 42,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  borderStartEndRadius: "inherit",
                  // borderStartStartRadius: "18px",
                  borderEndEndRadius: "inherit",
                  transform: "translateY(155%)",
                }}
              > */}
              {/* </Box> */}
              <CardContent
                sx={{
                  boxSizing: "border-box",
                  padding: "30px",
                  pt: 0,
                  borderRadius: 4,
                  height: "87vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  transition: "all 0.5s",
                  background: cardDetails.bg,
                }}
              >
                <Grid
                  sx={{ height: "100%", alignItems: "flex-start" }}
                  container
                  spacing={0}
                >
                  <Grid
                    sx={{ alignItems: "flex-start" }}
                    container
                    spacing={3}
                    item
                    xs={12}
                    md={5}
                  >
                    <Grid sx={{ height: "fit-content" }} item xs={12}>
                      <Typography
                        sx={{
                          textAlign: "start",
                          mt: 5,
                          fontSize: "2rem",
                          fontWeight: 600,
                          color: `${darken(cardDetails.bg, 0.6)}`,
                        }}
                      >
                        {cardDetails.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        textAlign="justify"
                        sx={{
                          fontSize: "1.15rem",
                          fontWeight: 600,
                          maxWidth: 350,
                          color: `${darken(cardDetails.bg, 0.6)}`,
                        }}
                      >
                        {cardDetails.description}
                      </Typography>
                    </Grid>
                    {cardDetails.features && (
                      <Grid
                        sx={{ alignItems: "flex-start" }}
                        container
                        spacing={1}
                        item
                        xs={12}
                      >
                        {cardDetails.features.map((feature) => (
                          <Grid item xs={12}>
                            <Card
                              sx={{
                                // my: 1,
                                background: lighten(cardDetails.bg, 0.4),
                                p: 2,
                                borderRadius: 2,
                                textAlign: "start",
                                letterSpacing: "0.06em",
                                height: "fit-content",
                                color: `${darken(cardDetails.bg, 0.6)}`,
                              }}
                              elevation={0}
                            >
                              {feature.description}
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                    <Grid
                      sx={{
                        justifyContent: "flex-start",
                        display: "flex",
                        paddingInlineStart: 3,
                      }}
                      item
                      xs={12}
                    >
                      <Button
                        sx={{
                          background: `${darken(cardDetails.bg, 0.2)}`,
                          maxWidth: 180,
                          color: theme.palette.primary.contrastText,
                          p: 1,
                          borderRadius: 3,
                          "&:hover": {
                            background: `${darken(cardDetails.bg, 0.3)}`,
                            color: `${theme.palette.getContrastText(
                              darken(cardDetails.bg, 0.3)
                            )}`,
                          },
                        }}
                        fullWidth
                      >
                        learn more
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      display: "flex",
                    }}
                    item
                    xs={12}
                    md={7}
                  >
                    <Box sx={{ maxHeight: 450, maxWidth: 400, height: "100%" }}>
                      {cardDetails.imgSection}
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        ))}
      </List>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${pastelBg})`,
          filter: "opacity(0.4)",
          zIndex: 0,
        }}
      />
    </Box>
  );
};

export default StackingCards;
