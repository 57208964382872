import { LockOutlined } from "@mui/icons-material";
import { Alert, Avatar, Box, Button, Checkbox, Container, CssBaseline, FormControlLabel, Grid, Link, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Copyrights from "../Components/Copyrights";
import { useMicrospecDynamicUIContext } from "../GlobalProvider";
import { useMicrospecThemeLanguageContext } from "../Themes";
import AuthWrapper from "./AuthWrapper";

const Login = ({ config }) => {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo')
  const { cloudProvider } = useMicrospecDynamicUIContext()
  const { localizationProvider } = useMicrospecThemeLanguageContext()
  const enableRegister = config.application.auth.enableRegister
  const signIn = localizationProvider.translateByType({
    type: "Internal",
    text: "signIn"
  })
  const { signInWithEmailAndPassword } = cloudProvider
  const [error, setError] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const result = await signInWithEmailAndPassword(data.get('email'), data.get('password'), data.get('remember'))
    setError(!result)
  };

  return (
    <AuthWrapper redirectTo={redirectTo}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1 }}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            {signIn}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={localizationProvider.translateByType({
                type: "Internal",
                text: "emailAddress"
              })}
              name="email"
              autoComplete="email"
              autoFocus
              dir="rtl"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={localizationProvider.translateByType({
                type: "Internal",
                text: "password"
              })}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {error && <Alert severity='error'>{localizationProvider.translateByType({
              type: "Internal",
              text: "emailOrPasswordInvalid"
            })}</Alert>}
            <FormControlLabel
              control={<Checkbox name="remember" id="remember" defaultValue={true} value={true} />}
              label={localizationProvider.translateByType({
                type: "Internal",
                text: "rememberMe"
              })}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {signIn}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href={redirectTo ? "/forgot?redirectTo=" + redirectTo : "/forgot"} variant="body2">
                  <Typography>{localizationProvider.translateByType({
                    type: "Internal",
                    text: "forgotPassword"
                  })}</Typography>
                </Link>
              </Grid>
              {enableRegister && <Grid item>
                <Link href={redirectTo ? "/register?redirectTo=" + redirectTo : "/register"} variant="body2">
                  <Typography>{localizationProvider.translateByType({
                    type: "Internal",
                    text: "newAccount"
                  })}</Typography>
                </Link>
              </Grid>}
            </Grid>
          </Box>
        </Box>
        <Copyrights sx={{ mt: 8, mb: 4 }} />
      </Container>
    </AuthWrapper>
  );
};

export default Login;
