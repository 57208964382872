import { cloneElement, isValidElement } from "react"
import CallToActionBanner from "./CallToActionBanner"
import CollapsableFaqWithFloatingPhoto from "./CollapsableFaqWithFloatingPhoto"
import DescriptionWithProgressBarAside from "./DescriptionWithProgressBarAside"
import EllipsePhotoWithParagaphAside from "./EllipsePhotoWithParagaphAside"
import FieldsOfExperience from "./FieldsOfExperience"
import FullScreenTitledImageSection from "./FullScreenTitledImageSection"
import HeroCTACard from "./HeroCTACard"
import NewsLetterCard from "./NewsLetterCard"
import RoundedImageAndTextSquareAside from "./RoundedImageAndTextSquareAside"
import ServicesAsymmetricGrid from "./ServicesAsymmetricGrid"
import TitledIconedCardsGrid from "./TitledIconedCardsGrid"
import HeroSectionWithScrollTrigger from "./HeroSectionWithScrollTrigger"
import StackingCards from "./StackingCards"
import FooterWithCallToActionBanner from "./FooterWithCallToActionBanner"
import CardWithTextAndCountersSection from "./CardWithTextAndCountersSection"

const specToSectionMapper = {
    // Above Done
    CallToActionBanner: <CallToActionBanner />,
    CollapsableFaqWithFloatingPhoto: <CollapsableFaqWithFloatingPhoto />,
    DescriptionWithProgressBarAside: <DescriptionWithProgressBarAside />,
    EllipsePhotoWithParagaphAside: <EllipsePhotoWithParagaphAside />,
    FieldsOfExperience: <FieldsOfExperience />,
    HeroCTACard: <HeroCTACard />,
    NewsLetterCard: <NewsLetterCard />,
    RoundedImageAndTextSquareAside: <RoundedImageAndTextSquareAside />,
    ServicesAsymmetricGrid: <ServicesAsymmetricGrid />,
    FullScreenTitledImageSection: <FullScreenTitledImageSection />,
    TitledIconedCardsGrid: <TitledIconedCardsGrid />,
    HeroSectionWithScrollTrigger: <HeroSectionWithScrollTrigger />,
    StackingCards: <StackingCards />,
    CardWithTextAndCountersSection: <CardWithTextAndCountersSection />,
    FooterWithCallToActionBanner: <FooterWithCallToActionBanner />,
}

const getSectionsMapper = (spec) => {
    const section = specToSectionMapper[spec.type]
    const processed = isValidElement(section) ? cloneElement(section, spec) : section
    return processed
}

export default getSectionsMapper