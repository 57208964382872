import { arSA, enUS, heIL } from '@mui/material/locale';
import { createTheme } from "@mui/material/styles";
import componentStyleOverrides from "./compStyleOverride";
import themePalette from "./palette";
import themeTypography from "./typography";

const getLanguageProps = (choosen) => {
    switch (choosen) {
        case 'ar':
            return arSA;
        case 'he':
            return heIL;
        case 'en':
            return enUS;
        default:
            throw new Error(`${choosen} is not supported.`)
    }
}

const theme = (config, choosen) => {
    return createTheme({
        direction: null,
        palette: themePalette(config),
        typography: themeTypography({}),
        components: componentStyleOverrides({})
    }, getLanguageProps(choosen))
};

export default theme