import { OpenInNewOutlined } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { useId } from "react";
import { useNavigate } from "react-router-dom";

const FullScreenTitledImageSection = ({ spec, type }) => {
    const navigate = useNavigate()
    const id = useId()
    const fullId = `${type}-${id}`

    const mainTitle = spec.mainTitle ? spec.mainTitle : {}
    const mainTitleText = mainTitle.text
    const mainTitleColor = mainTitle.color

    const secondaryTitle = spec.secondaryTitle ? spec.secondaryTitle : {}
    const secondaryTitleText = secondaryTitle.text
    const secondaryTitleColor = secondaryTitle.color

    const backgroundImage = spec.backgroundImage ? spec.backgroundImage : {}
    const backgroundImageSource = backgroundImage.source
    const backgroundImageDarkenFactor = backgroundImage.darkenFactor ? backgroundImage.darkenFactor : 0

    const buttons = spec.buttons

    return (
        <Stack sx={{ alignItems: 'center', position: 'relative', width: '100%', height: '100vh', backgroundImage: `url(${backgroundImageSource})`, backgroundAttachment: 'fixed', backgroundSize: "cover" }} key={`upper-stack-${fullId}`}>
            <Stack sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', zIndex: 2, gap: 2, backgroundColor: `rgba(52, 52, 52, ${backgroundImageDarkenFactor})`, backgroundAttachment: 'fixed' }} key={`inner-stack-${fullId}`}>
                <Typography variant="h1" color={mainTitleColor} sx={{ width: '50%', textAlign: 'center' }} key={`main-text-${fullId}`} >
                    {mainTitleText}
                </Typography>
                <Typography variant="h3" sx={{ width: '60%', textAlign: 'center' }} color={secondaryTitleColor} key={`secondary-text-${fullId}`}>
                    {secondaryTitleText}
                </Typography>
                <Stack sx={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', zIndex: 2, gap: 2 }}>
                    {
                        Object.values(buttons).map((value, index) => {
                            value.redirectTo = value.redirectTo ? value.redirectTo : {}
                            return (
                                <Button
                                    variant="contained"
                                    sx={{ background: value.color }}
                                    onClick={() => {
                                        if (value.redirectTo.external) {
                                            window.open(value.redirectTo.link, "_blank")
                                        } else {
                                            navigate(value.redirectTo.link)
                                        }
                                    }}
                                    key={`fullId-button-${index}`}
                                >
                                    {value.text}
                                    {value.redirectTo.external && <OpenInNewOutlined fontSize="small" />}
                                </Button>
                            )
                        })
                    }
                </Stack>
            </Stack>
        </Stack>
    )
}

export default FullScreenTitledImageSection;