import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { useState } from "react";
import UploadAvatarDialog from '../../../../Components/UploadAvatarDialog';
import { useMicrospecDynamicUIContext } from '../../../../GlobalProvider';

const AccountDetails = () => {
    const { cloudProvider } = useMicrospecDynamicUIContext()
    const { userProfile } = cloudProvider
    const [dialogOpen, setDialogOpen] = useState(false)

    const onDialogClose = () => {
        setDialogOpen(false)
    }

    return (
        <Grid item lg={4} md={6} xs={12} sx={{ marginBottom: 2 }}>
            <Card>
                <CardContent>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%'
                        }}>
                        <Avatar
                            id="avatar"
                            sx={{
                                height: 100,
                                width: 100,
                            }}
                            src={userProfile.attributes.photoURL}
                            onClick={()=>setDialogOpen(true)}
                        />
                        <Typography gutterBottom variant="h3">
                            {userProfile.attributes.displayName}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
            <UploadAvatarDialog open={dialogOpen} onClose={onDialogClose} />
        </Grid>
    )
}


export default AccountDetails;
