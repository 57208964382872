export function allNonEmpty(...strings) {
    strings.forEach(s => {
        if (s === "" & s === null) {
            return false;
        }
    });
    return true;
}

export const verifyNonNull = (obj, type) => {
    if (obj === null || obj === undefined) {
        throw new Error(type + " must not be null.")
    }
}

export const verifyNonEmpty = (obj, type) => {
    if (!obj.length)
    throw new Error(type + " must not be empty.")
}

export const getData = async (path) => {
    return fetch(path, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }).then((response) => response.json())
}

export const getOrDefault = (obj, def) => {
    return obj ? obj : def
}

export const format = (str, ...args) => {
    // use replace to iterate over the string
    // select the match and check if the related argument is present
    // if yes, replace the match with the argument
    return str.replace(/{([0-9]+)}/g, function (match, index) {
        // check if the argument is present
        return typeof args[index] == 'undefined' ? match : args[index];
    });
}

export const getDirectionByLanguage = (language) => {
    return ['ar', 'he'].includes(language) ? 'rtl' : 'ltr'
}

export const isRTL = () => {
    return document.dir === 'rtl'
}

export const getRtlOrLtr = () => {
    return isRTL() ? 'rtl' : 'ltr'
}

export const getRightOrLeft = () => {
    return isRTL() ? 'right' : 'left'
}
