import {
    Card,
    CardContent,
    CardHeader,
    Container,
    Grid,
    Typography,
    useTheme,
} from "@mui/material";

const FieldsOfExperience = () => {
  const theme = useTheme();
  const fontSize = "18px";
  return (
    <Container maxWidth="lg">
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 4,
        }}
        component="section"
        container
        spacing={2}
      >
        <Grid spacing={2} container item xs={12} sm={8} md={8}>
          <Grid item xs={12} sm={6} md={6}>
            <Card
              elevation={2}
              sx={{
                height: "100%",
                backgroundColor: "#a189ff",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <CardHeader
                sx={{ pt: 0 }}
                title={
                  <img
                    alt="alt"
                    src="https://design-portifolio-tan.vercel.app/assets/pattern-graphic-design-6be90807.svg"
                    style={{ width: "128px", height: "192px" }}
                  />
                }
                titleTypographyProps={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              />
              <CardContent>
                <Typography
                  sx={{
                    color: theme.palette.primary.contrastText,
                    fontSize: `${fontSize}`,
                    fontWeight: 600,
                    letterSpacing: "6px",
                  }}
                >
                  Graphic Design
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid container spacing={2} item xs={12} sm={6} md={6}>
            <Grid item xs={6}>
              <Card sx={{ backgroundColor: "#f6a560" }} elevation={2}>
                <CardHeader
                  sx={{ pt: 0 }}
                  title={
                    <img
                      alt="alt"
                      src="https://design-portifolio-tan.vercel.app/assets/pattern-ui-ux-9881e93a.svg"
                      style={{ width: "64px", height: "64px" }}
                    />
                  }
                  titleTypographyProps={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 2,
                  }}
                />
                <CardContent>
                  <Typography
                    sx={{
                      color: theme.palette.primary.contrastText,
                      fontSize: `${fontSize}`,
                      fontWeight: 600,
                      letterSpacing: "6px",
                    }}
                  >
                    UI/UX
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card sx={{ backgroundColor: "#f39e9e" }} elevation={2}>
                <CardHeader
                  sx={{ pt: 0 }}
                  title={
                    <img
                      alt="alt"
                      src="https://design-portifolio-tan.vercel.app/assets/pattern-apps-6d35ea19.svg"
                      style={{ width: "64px", height: "64px" }}
                    />
                  }
                  titleTypographyProps={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 2,
                  }}
                />
                <CardContent>
                  <Typography
                    sx={{
                      color: theme.palette.primary.contrastText,
                      fontSize: `${fontSize}`,
                      fontWeight: 600,
                      letterSpacing: "6px",
                    }}
                  >
                    Apps
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ backgroundColor: "#ff7d66" }} elevation={2}>
                <CardHeader
                  sx={{ pt: 0 }}
                  title={
                    <img
                      alt="alt"
                      src="https://design-portifolio-tan.vercel.app/assets/pattern-illustrations-f22832f6.svg"
                      style={{ width: "128px", height: "64px" }}
                    />
                  }
                  titleTypographyProps={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 2,
                  }}
                />
                <CardContent>
                  <Typography
                    sx={{
                      color: theme.palette.primary.contrastText,
                      fontSize: `${fontSize}`,
                      fontWeight: 600,
                      letterSpacing: "6px",
                    }}
                  >
                    Illustrations
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} item xs={12} sm={4} md={4}>
          <Grid item xs={12}>
            <Card sx={{ backgroundColor: "#61c4b7" }} elevation={2}>
              <CardHeader
                sx={{ pt: 0 }}
                title={
                  <img
                    alt="alt"
                    src="https://design-portifolio-tan.vercel.app/assets/pattern-photography-85df7c3b.svg"
                    style={{ width: "128px", height: "64px" }}
                  />
                }
                titleTypographyProps={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 2,
                }}
              />
              <CardContent>
                <Typography
                  sx={{
                    color: theme.palette.primary.contrastText,
                    fontSize: `${fontSize}`,
                    fontWeight: 600,
                    letterSpacing: "6px",
                  }}
                >
                  Photography
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ backgroundColor: "#a20f80" }} elevation={2}>
              <CardHeader
                sx={{ pt: 0 }}
                title={
                  <img
                    alt="alt"
                    src="https://design-portifolio-tan.vercel.app/assets/pattern-motion-graphics-a4ebae4f.svg"
                    style={{ width: "128px", height: "64px" }}
                  />
                }
                titleTypographyProps={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 2,
                }}
              />
              <CardContent>
                <Typography
                  sx={{
                    color: theme.palette.primary.contrastText,
                    fontSize: `${fontSize}`,
                    fontWeight: 600,
                    letterSpacing: "6px",
                  }}
                >
                  Motion Graphics
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
export default FieldsOfExperience;
