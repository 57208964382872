import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  alpha,
  lighten,
  darken,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { OpenInNewOutlined } from "@mui/icons-material";
import { getOrDefault, verifyNonNull } from "../../../Helpers/Utils";
import { useNavigate } from "react-router-dom";
import bg from "./wavesPattern.svg";

const EllipsePhotoWithParagaphAside = ({ spec, type, index }) => {
  const theme = useTheme();
  const navigate = useNavigate()
  const matchMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const mainPrimaryColor = theme.palette.primary.main
  const lightMainPrimaryColor = lighten(mainPrimaryColor, 0.5)
  const darkenedPrimaryColor = darken(mainPrimaryColor, 0.2)
  const mainContrastColor = theme.palette.primary.contrastText

  const mainBackgroundColor = getOrDefault(spec.mainBackgroundColor, mainPrimaryColor)

  const imageURL = spec.imageURL
  verifyNonNull(imageURL, `Image URL in ${type} in index ${index}`)
  
  const imageMainText = getOrDefault(spec.imageMainText, "Microspec")
  const imageMainColor = getOrDefault(spec.imageMainColor, lightMainPrimaryColor)

  const imageSecondaryText = getOrDefault(spec.imageSecondaryText, "Secondary Title")
  const imageSecondaryColor = getOrDefault(spec.imageMainColor, mainContrastColor)

  // Main Text
  const mainTitle = getOrDefault(spec.mainTitle, {})
  const mainTitleText = mainTitle.text
  const mainTitleColor = getOrDefault(mainTitle.color, lightMainPrimaryColor)
  verifyNonNull(mainTitleText, `Main title in ${type} in index ${index}`)

  const description = getOrDefault(spec.description, {})
  const descriptionText = getOrDefault(description.text, "Description.")
  const descriptionColor = getOrDefault(description.color, mainContrastColor)

  const buttons = getOrDefault(spec.buttons, [])

  return (
    <Grid
      container
      component="section"
      sx={{
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        p: 6,
        backgroundColor: mainBackgroundColor,
        gap: matchMdDown ? 3 : 0
      }}
    >
      <Box
        key="waves pattern"
        sx={{
          position: "absolute",
          height: "100%",
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          width: "100%",
          filter: "opacity(0.3)",
        }}
      />
      <Grid item sm={12} md={6} sx={{ position: "relative" }}>
        <Box
          sx={{
            borderRadius: "200px",
            height: matchMdDown ? 300 : 520,
            maxWidth: !matchMdDown ? 300 : "100%",
            width: matchMdDown ? "90vw" : "100%",
            backgroundImage: `linear-gradient(to right, ${alpha(mainPrimaryColor, 0.3)}, ${alpha(
              theme.palette.primary.contrastText,
              0.2
            )} ), url(${imageURL})`,
            backgroundSize: "cover",
            objectFit: "",
            "&:after": {
              border: "1px solid rgba(255,255,255,0.1)",
              borderRadius: "200px",
              content: '""',
              position: "absolute",
              top: -25,
              left: -25,
              right: -25,
              bottom: -25,
              maxWidth: !matchMdDown ? 350 : "calc(90vw + 50px)",
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack
              sx={{
                transform: !matchMdDown
                  ? "rotate(-90deg)"
                  : "translate(120px)",
                alignItems: "center",
                width: 250,
                ml: -6,
              }}
              direction="column"
              alignContent={"center"}
              alignItems={"center"}
            >
              <Typography
                sx={{
                  color: imageMainColor,
                  fontSize: "60px",
                }}
                alignSelf={"center"}
              >
                {imageMainText}
              </Typography>
              <Typography
                sx={{
                  fontSize: "30px",
                  color: imageSecondaryColor,
                }}
                alignSelf={"center"}
              >
                {imageSecondaryText}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Grid>
      <Grid item sm={12} md={6}>
        <Stack gap={2} sx={{ width: "100%" }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: 20,
              color: mainTitleColor,
              letterSpacing: "0.5rem",
              textAlign: "start",
            }}
          >
            {mainTitleText}
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontSize: 18,
              textAlign: "justify",
              color: descriptionColor,
              lineHeight: 1.5,
            }}
          >
            {descriptionText}
          </Typography>
          <Box
            sx={{
              width: "100%",
              mt: 4,
              display: "flex",
              justifyContent: "start",
              gap: 2
            }}
          >
            {
              Object.values(buttons).map((value, i) => {
                value.redirectTo = value.redirectTo ? value.redirectTo : {}
                const buttonBg = getOrDefault(value.backgroundColor, darkenedPrimaryColor)
                const hoverBg = lighten(buttonBg, 0.3)

                return (
                  <Button
                    id={`${type}-main-box-${index}-button-item-${i}`}
                    key={`${type}-main-box-${index}-button-item-${i}`}
                    variant={getOrDefault(value.variant, "contained")}
                    sx={{
                      background: buttonBg,
                      color: getOrDefault(value.color, "#ffffff"),
                      "&:hover": {
                        background: hoverBg,
                      }
                    }}
                    onClick={() => {
                      if (value.redirectTo.external) {
                        window.open(value.redirectTo.link, "_blank")
                      } else {
                        navigate(value.redirectTo.link)
                      }
                    }}
                    startIcon={value.redirectTo.external && <OpenInNewOutlined />}
                  >
                    {value.text}
                  </Button>
                )
              })
            }
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};
export default EllipsePhotoWithParagaphAside;
