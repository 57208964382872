import { verifyNonNull } from "../../Helpers/Utils"

export const getText = (obj, validationMessage) => {
    const text = obj.text
    verifyNonNull(text, validationMessage)
    return text
}

export const getTextColor = (obj) => {
    return obj.color
}