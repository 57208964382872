import { Box, Dialog, DialogContent, DialogTitle, Divider } from "@mui/material";
// import { useState } from "react";
const UploadAvatarDialog = ({ open, onClose }) => {
    // const snackbarInitialState = {
    //     msg: '',
    //     open: false,
    //     severity: ''
    // }
    // const [snackbarMsg, setSnackbarMsg] = useState(snackbarInitialState);
    // const closeSnackBar = () => {
    //     setSnackbarMsg(snackbarInitialState);
    // };

    // const avatarPreviewInitialState = {
    //     preview: null,
    //     src: ''
    // };
    // const [avatarPreview, setAvatarPreview] = useState(avatarPreviewInitialState);
    // const onCloseAvatarEditImg = () => {
    //     setAvatarPreview(avatarPreviewInitialState);
    // };

    // const onCrop = (preview) => {
    //     setAvatarPreview({ ...avatarPreview, preview });
    // };


    // function convertDataURIToBinary(dataURI) {
    //     const BASE64_MARKER = ';base64,';
    //     const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    //     const base64 = dataURI.substring(base64Index);
    //     const raw = window.atob(base64);
    //     const rawLength = raw.length;
    //     const array = new Uint8Array(new ArrayBuffer(rawLength));

    //     for (let i = 0; i < rawLength; i++) {
    //         array[i] = raw.charCodeAt(i);
    //     }
    //     return array;
    // }

    return (
        <Box>
            <Dialog fullWidth onClose={onClose} open={open}>
                <DialogTitle variant="h2" sx={{ alignSelf: "center" }}>Change User Avatar</DialogTitle>
                <Divider />
                <DialogContent sx={{ alignContent: "center", alignItems: "center", alignSelf: "center" }}>

                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default UploadAvatarDialog