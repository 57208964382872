/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

import { getOrDefault } from "../Helpers/Utils";

// https://coolors.co/d9ed92-b5e48c-99d98c-76c893-52b69a-34a0a4-168aad-1a759f-1e6091-184e77
export default function themePalette(config) {
    const application = getOrDefault(config.application, {})
    const theme = getOrDefault(application.theme, {})
    // console.log(theme)
    return theme;
}
