import useBlackHole from "./BlackHole";
import useFirebase from "./Firebase";
import useAWS from "./AWS"

const BLACKHOLE = "BLACKHOLE"
const providerMapper = {
    BLACKHOLE: useBlackHole,
    FIREBASE: useFirebase,
    AWS: useAWS
}
const useCloudProviderProperties = (config) => {
    const provider = process.env.REACT_APP_CLOUD_PROVIDER ?? BLACKHOLE
    return providerMapper[provider](config);
}

export default useCloudProviderProperties