import { Navigate } from "react-router-dom";
import Loader from "../Components/Loader";
import { useMicrospecDynamicUIContext } from "../GlobalProvider";

const AuthWrapper = ({ children, redirectTo }) => {
  const { cloudProvider } = useMicrospecDynamicUIContext()
  const { user, loading } = cloudProvider
  if (loading) {
    return <Loader />;
  }
  if (user) {
    redirectTo = redirectTo ? redirectTo : "/"
    return <Navigate to={redirectTo} replace />;
  }
  return children;
};

export default AuthWrapper;
