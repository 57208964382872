import { Box } from "@mui/system"
import { Fragment, useId } from "react"
import { getOrDefault, verifyNonNull } from "../../../Helpers/Utils"
import getSectionsMapper from "../../SectionsMapper"

const CompositeSectionsElement = (spec) => {
    const defaultCompositeSpec = getOrDefault(spec, {})
    const sections = getOrDefault(defaultCompositeSpec.sections, [])

    const id = useId()
    const fragmentId = `fragment-${spec.type}-${id}`
    return <Box>
        {
            sections.map((value, index) => {
                verifyNonNull(value, `Section in index ${index}`)
                verifyNonNull(value.type, `Type of section in index ${index}`)
                value.spec = getOrDefault(value.spec, {})
                value.index = index
                return (
                    <Fragment key={`${index}-${fragmentId}`}>
                        {getSectionsMapper(value, index)}
                    </Fragment>
                )
            })
        }
    </Box>
}

export default CompositeSectionsElement