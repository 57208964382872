import { CheckCircleOutline, Notifications, NotificationsActiveOutlined, NotificationsOutlined, TimerOutlined } from '@mui/icons-material';
import { Badge, Box, Button, Divider, IconButton, LinearProgress, List, ListItemButton, ListItemIcon, ListItemText, Popover, Tooltip, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { getData } from '../Helpers/Utils';
import { useMicrospecThemeLanguageContext } from '../Themes';

const getNotifications = async (setNotifications, setRetrieved) => {
  const data = await getData('/static/notifications/empty.json');
  setNotifications(data)
  setRetrieved(true)
  return data
}

const NotificationsPopover = () => {
  const { localizationProvider } = useMicrospecThemeLanguageContext()
  const [retrieved, setRetrieved] = useState(false)
  const [notifications, setNotifications] = useState([]);
  useMemo(() => {
    if (!retrieved) {
      getNotifications(setNotifications, setRetrieved)
    }
  }, [retrieved])

  const totalUnread = notifications.filter((item) => item.isUnread).length;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnread: false,
      }))
    );
  };

  return (
    <>
      <Tooltip title={localizationProvider.translateByType({
        type: "Internal",
        text: "notifications"
      })}>
        <IconButton
          id="notifications"
          size="large"
          aria-label="notifications"
          color="inherit"
          onClick={handleOpen}
        >
          <Badge badgeContent={retrieved ? totalUnread : 0} color="error">
            <Notifications />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {
          retrieved ?
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', py: 1, px: 1 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="subtitle1">{localizationProvider.translateByType({
                    type: "Internal",
                    text: "notifications"
                  })}</Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {localizationProvider.translateByType({
                      type: "Internal",
                      text: "newUnreadNotifications"
                    }, totalUnread <= 99 ? totalUnread : "99+")}
                  </Typography>
                </Box>

                {totalUnread > 0 && (
                  <Tooltip title="Mark all as read">
                    <IconButton color="primary" onClick={handleMarkAllAsRead}>
                      <CheckCircleOutline />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              <Divider sx={{ borderStyle: 'dashed' }} />
              <List
                disablePadding
              >
                {notifications.slice(0, notifications.length).map((notification) => (
                  <NotificationItem key={notification.id} notification={notification} />
                ))}
              </List>
            </> :
            <Box sx={{ display: 'flex', alignItems: 'center', py: 1, px: 1 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle1">{localizationProvider.translateByType({
                  type: "Internal",
                  text: "notifications"
                })}</Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {localizationProvider.translateByType({
                    type: "Internal",
                    text: "notificationsWaitMessage"
                  }, totalUnread <= 99 ? totalUnread : "99+")}
                </Typography>
                <LinearProgress fullWidth />
              </Box>
            </Box>
        }


        <Box sx={{ p: 1 }}>
          <Button fullWidth disabled>
            {
              localizationProvider.translateByType({
                type: "Internal",
                text: "viewAll"
              })
            }
          </Button>
        </Box>
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

function NotificationItem({ notification }) {

  const title = (
    <Typography sx={{ dir: "rtl", direction: "rtl" }} variant="subtitle2">
      {notification.title}
    </Typography>
  );

  return (
    <ListItemButton
      sx={{
        py: 0.2,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnread && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemIcon>
        {
          notification.isUnread ?
            <NotificationsActiveOutlined /> :
            <NotificationsOutlined />
        }
      </ListItemIcon>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <TimerOutlined sx={{ mr: 0.5, width: 16, height: 16 }} />
            {notification.createdAt}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default NotificationsPopover