// import "../App.css";
import MainHeader from "../components/header";

const MainPage = () => {
    return (
        <>
            <MainHeader />
        </>
    )
}

export default MainPage;