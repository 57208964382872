import React, { createContext, useContext } from "react";
import useCloudProviderProperties from "../Cloud";

const MicrospecDynamicUIContext = createContext();

const InternalMicrospecDynamicUIProvider = ({ children, config }) => {
    const cloudProviderProperties = useCloudProviderProperties(config)
    return (
        <MicrospecDynamicUIContext.Provider value={{ cloudProvider: cloudProviderProperties, config }}>
            {children}
        </MicrospecDynamicUIContext.Provider>
    )
}

export const useMicrospecDynamicUIContext = () => useContext(MicrospecDynamicUIContext)
export default InternalMicrospecDynamicUIProvider