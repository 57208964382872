const en = {
    signIn: "Sign In",
    optional: "Optional",
    userSettings: "User Settings",
    logout: "logout",
    account: "Account",
    security: "Security",
    firstName: "First Name",
    lastName: "Last Name",
    emailAddress: "Email Address",
    phone: "Phone",
    password: "Password",
    address: "Address",
    save: "Save",
    dangerZone: "Danger Zone",
    dangerZoneDeleteAccountText: "Click on the button below, in order to delete the account.",
    languages: "Languages",
    notifications: "Notifications",
    noNewNotifications: "No new notifications",
    getResetLinkAfterButtonClick: "Click on the button below to get a reset link to your email.",
    getResetLink: "Get reset link",
    passwordReset: "Reset Password",
    simpleElementText: "Let's create an awesome page by Microspec UI Foundation",
    pleaseWait: "Please Wait",
    deleteAccount: "Delete Account",
    cancel: "Cancel",
    confirm: "Confirm",
    deleteAccountConfirmationDialogText: "Please confirm account deletion",
    emailOrPasswordInvalid: "The email or password is invalid.",
    rememberMe: "Remember Me",
    forgotPassword: "Forgot password?",
    newAccount: "New Account?",
    signUp: "Sign me up",
    resetLinkSuccessfullySent: "Reset link is successfully sent to your email, please check it.",
    errorTryLater: "Something went wrong, please try again later.",
    new: "New",
    newUnreadNotifications: "You have {0} unread messages",
    notificationsWaitMessage: "Please wait while we retrieve notifications",
    viewAll: "View All"
}

const getEnglishMap = () => {
    return en
}

export default getEnglishMap