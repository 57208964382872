import { useTheme } from '@emotion/react';
import {
    Alert,
    Box, Card, CardContent, CardHeader,
    Divider,
    Grid,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useMicrospecDynamicUIContext } from "../../../../../GlobalProvider";
import ConfirmationDialog from '../../../../../Components/ConfirmationDialog';
import { useMicrospecThemeLanguageContext } from '../../../../../Themes';
const DeleteAccountSection = () => {
    const { cloudProvider } = useMicrospecDynamicUIContext()
    const { localizationProvider } = useMicrospecThemeLanguageContext()
    const [error, setError] = useState(false)
    const deleteAccountText = localizationProvider.translateByType({
        type: "Internal",
        text: "deleteAccount"
    })
    const handleSubmit = async () => {
        const result = await cloudProvider.deleteUser()
        setError(!result)
        if (result) {
            setInterval(() => {
                window.location.reload()
            }, 2000);
        }
    };
    const theme = useTheme()
    return (
        <Grid item lg={6} md={6} xs={6}>
            <Card component="form" >
                <CardHeader title={localizationProvider.translateByType({
                    type: "Internal",
                    text: "dangerZone"
                })} sx={{ color: theme.palette.error.main }} />
                <Divider />
                {error && <Alert severity='error'>{localizationProvider.translateByType({
                    type: "Internal",
                    text: "errorTryLater"
                })}</Alert>}
                <CardContent >
                    <Typography>{localizationProvider.translateByType({
                        type: "Internal",
                        text: "dangerZoneDeleteAccountText"
                    })}</Typography>
                </CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        p: 2
                    }}
                >
                    <ConfirmationDialog
                        outterButtonText={deleteAccountText}
                        dialogTitle={deleteAccountText}
                        dialogContent={localizationProvider.translateByType({
                            type: "Internal",
                            text: "deleteAccountConfirmationDialogText"
                        })}
                        dialogButtonText={deleteAccountText}
                        buttonsColor={"error"}
                        onDialogButtonClick={handleSubmit}
                    />
                </Box>
            </Card>
        </Grid>
    );
};

export default DeleteAccountSection;
