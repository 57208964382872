import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { useMicrospecThemeLanguageContext } from "../Themes";

const Loader = ({ msg }) => {

  const { localizationProvider } = useMicrospecThemeLanguageContext()

  return (
    <Backdrop
      open
      invisible
      sx={{ display: "flex", flexDirection: "column", gap: 1, zIndex:1301 }}
    >
      <CircularProgress />
      <Typography variant="subtitle1">{msg || localizationProvider.translateByType({
        type: "Internal",
        text: "pleaseWait"
      })}</Typography>
    </Backdrop>
  );
};

export default Loader;
