const ar = {
    signIn: "تسجيل الدخول",
    optional: "اختياري",
    userSettings: "إعدادات المستخدم",
    logout: "تسجيل الخروج",
    account: "الحساب",
    security: "الامان",
    firstName: "الاسم الاول",
    lastName: "اسم العائلة",
    emailAddress: "البريد الالكتروني",
    password: "كلمة المرور",
    phone: "رقم الهاتف",
    address: "العنوان",
    save: "حفظ",
    dangerZone: "منطقة خطر",
    dangerZoneDeleteAccountText: "اضغط على الزر بالاسفل لحذف الحساب",
    languages: "اللغات",
    notifications: "ألإشعارات",
    noNewNotifications: "لا يوجد اشعارات جديدة",
    getResetLinkAfterButtonClick: "اضغط على الزر بالاسفل للحصول على رابط استعادة كلمة المرور للبريد الالكتروني",
    getResetLink: "احصل على رابط لاستعادة كلمة المرور",
    passwordReset: "استعادة كلمة المرور",
    simpleElementText: "لنبني صفحة رائعة بواسطة اساسيات مايكروسبيك",
    pleaseWait: "الرجاء الانتظار",
    deleteAccount: "حذف الحساب",
    cancel: "الغاء",
    confirm: "تأكيد",
    emailOrPasswordInvalid: "البريد الالكتروني او كلمة المرور خطأ",
    rememberMe: "تذكرني",
    forgotPassword: "نسيت كلمة المرور؟",
    newAccount: "حساب جديد؟",
    signUp: "سجلني",
    resetLinkSuccessfullySent: "رابط استعادة كلمة المرور أُرسل الى بريدك الالكتروني بنجاح.",
    errorTryLater: "حدثت مشكلة, نأسف لإزعاجك, جرب مرة اخرى لاحقاً.",
    new: "جديد",
    newUnreadNotifications: "لديك {0} اشعارات جديدة",
    deleteAccountConfirmationDialogText: "الرجاء تأكيد حذف الحساب",
    notificationsWaitMessage: "الرجاء الانتظار حتى الانتهاء من جلب الاشعارات",
    viewAll: "اظهار الكل"
}

const getArabicMap = () => {
    return ar
}

export default getArabicMap
