import { Step, StepLabel, Typography } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import { ColorlibConnector, ColorlibStepIcon } from './CustomizedStepperStyle';
import { useMicrospecThemeLanguageContext } from '../../../Themes';
const CustomizedStepper = ({ props }) => {
    const { dir, localizationProvider } = useMicrospecThemeLanguageContext()
    const { activeStep, stepperObjects, isStepOptional, isStepSkipped } = props
    const icons = stepperObjects.reduce((iconsMap, currentValue, currentIndex) => {
        iconsMap[currentIndex + 1] = currentValue.icon
        return iconsMap;
    }, {})
    return (
        <Stepper sx={{ dir, direction: dir }} activeStep={activeStep} connector={<ColorlibConnector />}>
            {stepperObjects.map((stepperItem, index) => {
                const label = localizationProvider.translateByType(stepperItem.label)
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                    labelProps.optional = (
                        <Typography sx={{ mx: 2 }} variant="caption">{localizationProvider.translateByType({
                            type: 'Internal',
                            text: 'optional'
                        })}</Typography>
                    );
                }
                if (isStepSkipped(index)) {
                    stepProps.completed = false;
                }
                return (
                    <Step sx={{ mx: 1 }} key={label} {...stepProps}>
                        <StepLabel {...labelProps} StepIconComponent={
                            (properties) => {
                                return ColorlibStepIcon({ properties, icons })
                            }}> {label} </StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
}

export default CustomizedStepper