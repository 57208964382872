import styled from "@emotion/styled";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  darken,
  useMediaQuery,
  useTheme
} from "@mui/material";


const CustomTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "white",
  },
  "& input:focus + fieldset": {
    borderColor: "white",
  },
  "& input:hover": {
    borderColor: "white",
  },
});

const CallToActionCard = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Card
      elevation={0}
      sx={{
        mx: 3,
        maxWidth: 950,
        width: "80%",
        backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
        py: 3,
        borderRadius: 12,
        transform: "translateY(-55%)",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 0,
          py: "0 !important",
        }}
      >
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            gap: 2,
            p: 3,
            color: theme.palette.primary.contrastText,
          }}
        >
          <Typography sx={{ color: "inherit" }} variant="h5">
            Sounds like a match
          </Typography>
          <Typography
            variant="h1"
            fontWeight={600}
            sx={{
              fontSize: matchDownMd ? "2rem" : "3rem",
              width: "100%",
              color: "inherit",
              letterSpacing: "0.1rem",
            }}
          >
            Let's Grow Your Business
          </Typography>
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            gap={matchDownMd ? 1 : 0}
            sx={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "fit-content",
            }}
          >
            <CustomTextField
              fullWidth
              placeholder="Your email address"
              sx={{
                maxWidth: 300,
                "&:hover": {
                  "& input:valid + fieldset": {
                    borderColor: "white",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "white",
                },
              }}
              InputProps={{
                sx: {
                  borderEndEndRadius: !matchDownMd ? 0 : 4,
                  borderStartEndRadius: !matchDownMd ? 0 : 4,
                },
              }}
              label="Enter your email address"
              name="email"
            />
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.palette.primary.contrastText,
                color: "text.secondary",
                py: 2,
                borderEndStartRadius: !matchDownMd ? 0 : 2,
                borderStartStartRadius: !matchDownMd ? 0 : 2,
                boxShadow: "unset",
                "&:hover": {
                  boxShadow: theme.shadows[1],
                  backgroundColor: darken(
                    theme.palette.primary.contrastText,
                    0.1
                  ),
                  color: theme.palette.primary.main,
                },
              }}
            >
              Get started
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

const FooterRoutes = () => {
  const year = new Date().getFullYear();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      sx={{
        maxWidth: theme.breakpoints.values.lg,
        justifyContent: "center",
        alignItems: "center",
      }}
      spacing={2}
    >
      <Grid item xs={12} md={6}>
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "flex-start",
            paddingInlineStart: matchDownMd ? 4 : 2,
          }}
        >
          <Typography
            sx={{
              color: theme.palette.primary.contrastText,
            }}
            variant="h3"
          >
            Micro
            <Typography
              sx={{
                color: "#1A73E8",
                fontSize: "inherit",
              }}
              component="span"
            >
              Spec
            </Typography>
          </Typography>
          <Typography
            sx={{ color: theme.palette.primary.contrastText }}
            variant="body1"
          >
            Grow your business with our innovative solutions
          </Typography>
          <Stack
            direction="row"
            gap={1}
            sx={{ width: "100%", alignItems: "center" }}
          >
            {[
              {
                icon: (
                  <svg
                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                    //   focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="FacebookIcon"
                    style={{
                      width: "100%",
                      height: "100%",
                      fill: "teal",
                    }}
                  >
                    <path d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z"></path>
                  </svg>
                ),
                id: "facebook",
                key: 1,
              },
              {
                icon: (
                  <svg
                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                    //   focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="FacebookIcon"
                    style={{
                      width: "100%",
                      height: "100%",
                      fill: "aqua",
                    }}
                  >
                    <path d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z"></path>
                  </svg>
                ),
                id: "insta",
                key: 2,
              },
              {
                icon: (
                  <svg
                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                    //   focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="FacebookIcon"
                    style={{
                      width: "100%",
                      height: "100%",
                      fill: "tomato",
                    }}
                  >
                    <path d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z"></path>
                  </svg>
                ),
                id: "linkedin",
                key: 3,
              },
              {
                icon: (
                  <svg
                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                    //   focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="FacebookIcon"
                    style={{
                      width: "100%",
                      height: "100%",
                      fill: "#1A73E8",
                    }}
                  >
                    <path d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z"></path>
                  </svg>
                ),
                id: "twitter",
                key: 4,
              },
            ].map((btn) => (
              <IconButton key={btn.id} sx={{ width: 38, height: 38 }}>
                {btn.icon}
              </IconButton>
            ))}
          </Stack>
        </Stack>
      </Grid>
      <Grid
        sx={{ justifyContent: "flex-end", alignItems: "flex-start" }}
        item
        xs={12}
        md={6}
        container
        spacing={2}
      >
        {[
          {
            mainTitle: "Solutions",
            routes: [
              {
                href: "/seko",
                label: "Brand",
              },
              {
                href: "/beko",
                label: "Design",
              },
              {
                href: "/teko",
                label: "Video",
              },
              {
                href: "/meko",
                label: "Marketing",
              },
              {
                href: "/neko",
                label: "Dev",
              },
            ],
          },
          {
            mainTitle: "Company",
            routes: [
              {
                href: "/okes",
                label: "About",
              },
              {
                href: "/okeb",
                label: "Contact",
              },
              {
                href: "/oket",
                label: "Testimonials",
              },
              {
                href: "/okem",
                label: "Partners",
              },
            ],
          },
          {
            mainTitle: "Support",
            routes: [
              {
                href: "/help",
                label: "Help",
              },
              {
                href: "/terms",
                label: "TOS",
              },
              {
                href: "/legal",
                label: "Legal",
              },
            ],
          },
        ].map((routesList) => (
          <Grid item xs={6} sm={4} md={3}>
            <Stack sx={{ justifyContent: "center", alignItems: "flex-start" }}>
              <Typography
                color="primary.contrastText"
                variant="subtitle1"
                fontWeight={600}
              >
                {routesList.mainTitle}
              </Typography>
              {routesList.routes &&
                routesList.routes.map((route) => (
                  <Typography
                    component="a"
                    href={route.href}
                    variant="subtitle2"
                    color="grey"
                    sx={{
                      textDecoration: "none",
                      "&:hover": {
                        // textDecoration: "underline",
                        color: "primary.contrastText",
                        transition: "all 0.25s",
                        // "&:after": {
                        //   content: '">"',
                        //   mx: 1,
                        //   width: 1,
                        //   transition: "all 0.5s",
                        // },
                      },
                    }}
                  >
                    {route.label}
                  </Typography>
                ))}
            </Stack>
          </Grid>
        ))}
      </Grid>
      <Grid
        sx={{
          my: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
        }}
        item
        xs={12}
      >
        <Divider
          sx={{
            width: "70%",
            // mx: "auto",
            backgroundColor: theme.palette.primary.contrastText,
          }}
        />
        <Typography variant="body1" sx={{ color: "grey" }}>
          © All rights are reserved for MicroSpec {year}
        </Typography>
      </Grid>
    </Grid>
  );
};

const FooterWithCallToActionBanner = () => {
  return (
      // <Box sx={{ height: "25vh", background: theme.palette.primary.main }} />
      <Paper
        sx={{
          backgroundColor: "#242742",
          minHeight: "500px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <CallToActionCard />
        <FooterRoutes />
      </Paper>
  );
};
export default FooterWithCallToActionBanner;
