import {
    Alert,
    Box,
    Button,
    Card, CardContent, CardHeader,
    Divider,
    Grid,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useMicrospecDynamicUIContext } from "../../../../../GlobalProvider";
import { useMicrospecThemeLanguageContext } from '../../../../../Themes';
const PasswordResetSection = () => {
    const { cloudProvider } = useMicrospecDynamicUIContext()
    const { localizationProvider } = useMicrospecThemeLanguageContext()
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const handleSubmit = async (event) => {
        event.preventDefault()
        const result = await cloudProvider.sendPasswordResetLink()
        setSuccess(result)
        setError(!result)
    };

    return (
        <Grid item lg={6} md={6} xs={6}>
            <Card component="form" onSubmit={handleSubmit} >
                <CardHeader title={localizationProvider.translateByType({
                    type: "Internal",
                    text: "passwordReset"
                })} />
                <Divider />
                {success && <Alert>{localizationProvider.translateByType({
                    type: "Internal",
                    text: "resetLinkSuccessfullySent"
                })}</Alert>}
                {error && <Alert severity='error'>{localizationProvider.translateByType({
                    type: "Internal",
                    text: "errorTryLater"
                })}</Alert>}
                <CardContent >
                    <Typography>{localizationProvider.translateByType({
                        type: "Internal",
                        text: "getResetLinkAfterButtonClick"
                    })}</Typography>
                </CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        p: 2
                    }}
                >
                    <Button
                        type="submit"
                        variant="contained"
                    >
                        {localizationProvider.translateByType({
                            type: "Internal",
                            text: "getResetLink"
                        })}
                    </Button>
                </Box>
            </Card>
        </Grid>
    );
};

export default PasswordResetSection;
