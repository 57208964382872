import { ArrowBack } from "@mui/icons-material"
import { Box, Button, Container, Link, Typography } from "@mui/material"
import NotFoundSvg from "./NotFoundSvg"

const NotFoundElement = () => {
    return (
        <Box
            component="main"
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexGrow: 1,
                minHeight: '20%'
            }}
            my={10}
        >
            <Container maxWidth="md">
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Typography
                        align="center"
                        color="textPrimary"
                        variant="h1"
                        fontSize={59}
                        fontWeight={1000}
                    >The page you are looking for isn’t here</Typography>
                    <Typography
                        align="center"
                        color="textPrimary"
                        variant="subtitle1"
                        fontWeight={550}
                    >
                        Nothing here to see, please go to a valid page.
                    </Typography>
                    <Box sx={{ textAlign: 'center'}}>
                        <NotFoundSvg />
                    </Box>
                    <Link
                        href="/"
                    >
                        <Button
                            startIcon={(<ArrowBack fontSize="small" />)}
                            sx={{ mt: 3 }}
                            variant="contained"
                        >
                            Go back to home
                        </Button>
                    </Link>
                </Box>
            </Container>
        </Box>
    )
}

export default NotFoundElement