import {
    List
} from "@mui/material";
import DrawerListItems from "./DrawerListItems";

const DrawerSpecificContent = ({ data }) => {
    const filterPredicate = ((value) => !value.common)
    return (
        <List>
            <DrawerListItems data={data} filterPredicate={filterPredicate}/>
        </List>
    )
}
export default DrawerSpecificContent