import { CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material";
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { createContext, useContext, useMemo } from "react";
import { initReactI18next, useTranslation } from 'react-i18next';
import { format, getDirectionByLanguage, verifyNonNull } from "../Helpers/Utils";
import getLanguageProvider from "./Languages";
import theme from "./Theme";

// const cssToMuiLoader = cssToMui("./assets/css/style.css")
const MicrospecThemeLanguageContext = createContext()

const languageLocalStorageKey = 'language'

const MicrospecThemeProvider = ({ children, config }) => {
    const localization = useMemo(() => handleLocalization(config.languages), [config.languages])
    const { t } = useTranslation()
    const { dir, choosen, internalLanguageProvider } = localization
    const translateByType = (obj, ...args) => {
        if (!obj || !obj.text) {
            return "UNAVAILABLE"
        }
        let value;
        switch (obj.type) {
            case "Localized":
                value = t(obj.text)
                break;
            case "Internal":
                value = internalLanguageProvider(obj.text)
                break;
            default:
                value = obj.text
                break;
        }
        return format(value, ...args)
    }
    const localizationProvider = { dir, choosen, translateByType }
    return (
        <MicrospecThemeLanguageContext.Provider value={{ config, localizationProvider }}>
            <ThemeProvider theme={theme(config, choosen)}>
                <StyledEngineProvider injectFirst>
                    <CssBaseline />
                    {children}
                </StyledEngineProvider>
            </ThemeProvider>
        </MicrospecThemeLanguageContext.Provider>
    )
}

const initI18nIfNeeded = (defaultLanguage, choosenLanguage) => {
    if (!i18n.isInitialized) {
        i18n.use(Backend).use(initReactI18next)
            .init({
                lng: choosenLanguage,
                fallbackLng: defaultLanguage,
            });
    }
}

const handleLocalization = (languages) => {
    verifyLanguages(languages)
    const choosen = handleLanguageLocalStorageFlow(languages)
    initI18nIfNeeded(languages.default, choosen)
    const dir = handleDirectionByLanguage(languages.choosen)
    const internalLanguageProvider = (key) => {
        const provider = getLanguageProvider(choosen)
        const value = provider[key]
        return value ? value : key
    }
    return { choosen, dir, internalLanguageProvider }
}
//
const verifyLanguages = (languages) => {
    verifyNonNull(languages, 'languages')
    verifyNonNull(languages.default, 'languages.default')
    verifyNonNull(languages.list, 'languages.list')
    languages.codes = languages.list.map((v) => {
        verifyNonNull(v.code)
        verifyNonNull(v.name)
        return v.code
    })//
}

const handleLanguageLocalStorageFlow = (languages) => {
    const localized = localStorage.getItem(languageLocalStorageKey);
    const choosen = localized !== null && languages.codes.includes(localized) ? localized : languages.default
    localStorage.setItem(languageLocalStorageKey, choosen)
    languages.choosen = choosen
    return choosen
}

const handleDirectionByLanguage = (choosen) => {
    const dir = getDirectionByLanguage(choosen)
    if (dir !== document.dir) {
        document.dir = dir
        document.documentElement.setAttribute('lang', choosen)
    }
    return dir
}

export const handleLanguageChange = (choosen) => {
    localStorage.setItem(languageLocalStorageKey, choosen)
    window.location.reload()
}

export const useMicrospecThemeLanguageContext = () => useContext(MicrospecThemeLanguageContext)
export default MicrospecThemeProvider