import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as React from 'react';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: document.dir === 'ltr' ?
                'linear-gradient( 95deg, ' + theme.palette.primary.main + ' 0%, ' + theme.palette.primary.main + ' 50%, ' + theme.palette.secondary.main + ' 100%)' :
                'linear-gradient( 95deg, ' + theme.palette.secondary.main + ' 0%, ' + theme.palette.primary.main + ' 50%, ' + theme.palette.primary.main + ' 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient( 95deg, ' + theme.palette.primary.main + ' 0%, ' + theme.palette.primary.main + ' 50%, ' + theme.palette.primary.main + ' 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 4,
        border: 100,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 2,
    marginRight: 2,
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 115deg, ' + theme.palette.secondary.main + ' 0%, ' + theme.palette.primary.main + ' 50%, ' + theme.palette.secondary.main + ' 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, ' + theme.palette.primary.main + ' 0%, ' + theme.palette.primary.main + ' 50%, ' + theme.palette.primary.main + ' 100%)',
    })
}));

function ColorlibStepIcon(props) {
    const { active, completed, icon, className } = props.properties;
    const icons = props.icons

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
    index: PropTypes.number
};

export { ColorlibConnector, ColorlibStepIcon };
