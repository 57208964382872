import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Link,
  Stack,
  Typography
} from "@mui/material";

const DescriptionWithProgressBarAside = () => {
  const skills = [
    {
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, ",
      progress: 100,
      title: "title1",
    },
    {
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, ",
      progress: 60,
      title: "title2",
    },
  ];
  return (
    <Grid
      sx={{
        justifyContent: "center",
        alignItems: "center",
        p: 6,
        display: "flex",
      }}
      container
      spacing={6}
      component="section"
    >
      <Grid
        sx={{ maxWidth: "600px !important" }}
        item
        container
        spacing={2}
        xs={12}
        sm={7}
        md={8}
      >
        {skills.map((skill) => (
          <Grid item xs={12} key={skill.description}>
            <Typography
              sx={{
                textAlign: "right",
                mb: 2,
                letterSpacing: "0.2rem",
                color: "#233d62",
              }}
              className="text-u ls3 fz-13"
            >
              {skill.title}
            </Typography>
            {/* <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "right",
              }}
            > */}
            <LinearProgress
              value={0}
              className="skill-progress"
              sx={{
                backgroundImage: `linear-gradient(-270deg, #ffcf6d ${
                  100 - skill.progress
                }%, #fd8558)`,
                height: 12,
                borderRadius: 2,
              }}
              variant="determinate"
              height={"10px"}
            />
            <Stack
              sx={{
                flexDirection: "row-reverse",
                justifyContent: "right",
                mt: 2,
                gap: 3,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "200 !important",
                  textAlign: "right",
                  fontSize: 40,
                  width: 120,
                  color: "#233d62",
                }}
              >
                {`%${skill.progress}`}
              </Typography>
              <Typography sx={{ textAlign: "justify" }} variant="body1">
                {skill.description}
              </Typography>
            </Stack>
            {/* </Stack> */}
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} sm={5} md={4}>
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "space-around",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: "#233d62",
              fontWeight: 700,
              mb: 3,
              textAlign: "justify",
              lineHeight: 1.5,
            }}
          >
            תמיד נהיה <br /> מוכנים לסייע.
          </Typography>
          <Typography
            sx={{ direction: "justify", lineHeight: 1.75 }}
            textAlign="justify"
            color="text.secondary"
            variant="body1"
          >
            ביחד יוצרים רשת חזקה ומאוחדת של חברות האחים. באמצעות החיבור, הלמידה
            המשותפת והתמיכה המוסרית, אנחנו מקדמים את המקצועיות והאיכות של
            השירותים הרפואיים בארץ
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "left",
              mt: 3,
            }}
          >
            <Button
              sx={{
                backgroundImage:
                  "radial-gradient(circle farthest-corner at 10% 20%, #fdc168 0%, #fb8080 90%)",
                px: 2,
                py: 1,
                width: 140,
                borderRadius: 3,
                "&:hover": {
                  backgroundImage:
                    "radial-gradient(circle farthest-corner at 20% 30%, #fdc168 0%, #fb8080 60%)",
                },
                color: "#fef",
                fontSize: 18,
                transition: "all 0.5s",
              }}
              href=""
            >
              אודותינו
            </Button>
          </Box>
          <Link href="/register">
            {/* <a className="butn butn-md gr-sunrise-bg text-light radius-30 mt-30"> */}
              {/* <Split> */}
              <span
                className="text words chars splitting"
                data-splitting
              ></span>
              {/* </Split> */}
            {/* </a> */}
          </Link>
        </Stack>
      </Grid>
    </Grid>
  );
};
export default DescriptionWithProgressBarAside;
