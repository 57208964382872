import { Box, Tab, Tabs } from "@mui/material";
import { React, useState } from "react";
import { useMicrospecThemeLanguageContext } from "../../../Themes";

const a11yProps = (index) => {
    const obj = {
        id: `simple-tab-${index.toString()}`,
        key: `simple-tab-${index.toString()}`,
        name: `simple-tab-${index.toString()}`,
        'aria-controls': `simple-tabpanel-${index.toString()}`,
    };
    return obj
}

const TabPanel = (props) => {
    const { children, value, index } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            aria-labelledby={`simple-tab-${index.toString()}`}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}

const UpperLabeledTabsElement = (spec) => {
    const obj = spec.object

    const [value, setValue] = useState(0)
    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    const { localizationProvider } = useMicrospecThemeLanguageContext()

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: 'inherit' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="dashboard page tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile>
                    {
                        Object.values(obj)
                            .map((v, index) => {
                                return <Tab label={localizationProvider.translateByType(v.name)} {...a11yProps(index)} />
                            })
                    }
                </Tabs>
            </Box>
            <Box marginTop={2} marginRight={2} marginLeft={2}>
                {
                    Object.values(obj)
                        .map((v, index) => {
                            return <TabPanel value={value} index={index} key={`tab-panel-element-${index}`}>
                                {v.element}
                            </TabPanel>
                        })
                }
            </Box>
        </Box>
    )
}

export default UpperLabeledTabsElement