import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import Loader from './Components/Loader';
import OfflineDialog from "./Components/OfflineDialog";
import ScrollToTop from "./Components/ScrollToTop";
import InternalMicrospecDynamicUIProvider from "./GlobalProvider";
import ApplicationRoutes from "./Helpers/Routes";
import MicrospecThemeProvider from "./Themes";

const MicrospecDynamicUI = ({ config }) => {
  return (
    <BrowserRouter>
      <InternalMicrospecDynamicUIProvider config={config}>
        <MicrospecThemeProvider config={config}>
          <Suspense fallback={<Loader />}>
            <ScrollToTop />
            <OfflineDialog config={config}/>
            <ApplicationRoutes config={config} />
          </Suspense>
        </MicrospecThemeProvider >
      </InternalMicrospecDynamicUIProvider>
    </BrowserRouter>
  );
};

export default MicrospecDynamicUI;
