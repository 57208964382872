import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { itemListReduce } from "../../Helpers/Pages";
import { useMicrospecThemeLanguageContext } from "../../Themes";
const DrawerListItems = ({ data, filterPredicate }) => {
    const navigate = useNavigate()
    const { mobileOpen, handleDrawerToggle, dashboard, item } = data
    const itemsMap = useMemo(() => itemListReduce(dashboard.items), [dashboard.items])
    const { localizationProvider } = useMicrospecThemeLanguageContext()
    return (
        Object.values(itemsMap)
            .filter(filterPredicate)
            .filter((value) => !value.hide)
            .map((value) => (
                <ListItemButton
                    id={value.path + "list-item-button"}
                    key={value.path + "list-item-button"}
                    name={value.path + "list-item-button"}
                    onClick={() => {
                        if (mobileOpen) {
                            handleDrawerToggle();
                        }
                        if (item.path !== value.path) {
                            navigate(dashboard.path + "/" + value.path, { replace: true })
                        }
                    }}
                    selected={item.path === value.path}
                >
                    <ListItemIcon
                        id={value.path + "list-item-icon"}
                        key={value.path + "list-item-icon"}
                        name={value.path + "list-item-icon"}>{value.icon}</ListItemIcon>
                    <ListItemText
                        id={value.path + "list-item-text"}
                        key={value.path + "list-item-text"}
                        name={value.path + "list-item-text"}
                        primary={localizationProvider.translateByType(value.name)} />
                </ListItemButton>
            ))
    );
}
//
export default DrawerListItems