import { Avatar, Grid, Stack, Typography, lighten, useTheme } from "@mui/material"
import { useId } from "react"
import MainCard from "../../../Components/MainCard"
import { getOrDefault } from "../../../Helpers/Utils"

const TitledIconedCardsGrid = ({ spec, type }) => {
    const theme = useTheme()
    const id = useId()
    const fullId = `${type}-auto-${id}`

    const title = spec.title
    const subtitle = spec.subtitle

    const background = getOrDefault(spec.background, {})
    const backgroundColor = getOrDefault(background.color, theme.palette.primary.light)
    const lightenFactor = getOrDefault(background.lightenFactor, 0.5)

    const cards = getOrDefault(spec.cards, [])

    return (
        <Stack sx={{ justifyContent: 'center', alignItems: 'center', position: 'relative', width: '100vw', backgroundColor: lighten(backgroundColor, lightenFactor) }} key={`upper-stack-${fullId}`}>
            <Stack sx={{ flexDirection: 'column', width: '75%', height: '100%', zIndex: 2, gap: 2, pt: 1, pb: 1, mb: 2 }} key={`inner-stack-${fullId}`}>
                {title && <Typography variant="h2">{title}</Typography>}
                {subtitle && <Typography variant="h4">{subtitle}</Typography>}
                {
                    <Grid container spacing={2}>
                        {
                            cards.map((card) => <Grid key={card.title} item xs={12} sm={6} md={4}>
                                <MainCard contentSX={{ height: '200px', overflowY: 'auto' }} boxShadow={true} content={card.subtitle} avatar={card.avatarImageURL} border={false} title={card.name}>
                                    <Stack flex={true} sx={{ flexDirection: 'row', gap: 2, height: '100%' }}>
                                        <Avatar variant="circular" src={card.avatarImageURL} />
                                        <Typography>{card.subtitle}</Typography>
                                    </Stack>
                                </MainCard>
                            </Grid>)
                        }
                    </Grid>
                }
            </Stack>
        </Stack>
    )
}

export default TitledIconedCardsGrid