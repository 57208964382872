import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const HeroCTACard = () => {
  const theme = useTheme();
  const matchMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container maxWidth="lg" sx={{ paddingY: "50px" }}>
      <Card
        sx={{
          bgcolor: "rgb(245, 137, 114)",
          borderRadius: 5,
        }}
      >
        <CardContent sx={{ maxHeight: 600, overflow: "unset" }}>
          <Grid container spacing={2}>
            <Grid
              sx={{ position: "relative", zIndex: 3 }}
              item
              xs={12}
              sm={7}
              md={6}
            >
              <Stack
                sx={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "flex-start",
                  pt: matchMdDown ? 4 : 8,
                  pl: !matchMdDown && 8,
                }}
                gap={3}
              >
                <Typography
                  sx={{
                    fontSize: matchMdDown ? 35 : 45,
                    fontWeight: 800,
                    textAlign: "start",
                    zIndex: 3,
                  }}
                  variant="h1"
                  color="primary.contrastText"
                >
                  Award-winning custom designs and digital branding solutions
                </Typography>
                <Box>
                  <Typography
                    sx={{
                      textAlign: "start",
                      fontSize: 18,
                      zIndex: 3,
                    }}
                    variant="body1"
                    color="primary.contrastText"
                  >
                    With over 10 years in the industry, we are experienced in
                    creating fully responsive websites, app design, and engaging
                    brand experiences. Find out more about our services.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: theme.palette.primary.contrastText,
                      maxWidth: 160,
                      py: 1,
                      lineHeight: 2,
                      color: "tomato",
                      "&:hover": {
                        backgroundColor: "rgb(255, 180, 164)",
                        color: "#242742",
                      },
                    }}
                  >
                    קרא עוד
                  </Button>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={5} md={6} sx={{ position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  backgroundImage: `linear-gradient(to bottom, ${alpha(
                    theme.palette.action.focus,
                    0.15
                  )}, ${alpha(theme.palette.action.focus, 0)})`,
                  width: "80vw",
                  maxWidth: 600,
                  height: 600,
                  borderRadius: 200,
                  top: matchMdDown ? "-8.5rem" : "3.5rem",
                  right: matchMdDown ? "1rem" : "-4rem",
                  zIndex: 1,
                }}
              />
              <Box
                sx={{
                  margin: matchMdDown ? "-9.5rem -10rem 0" : "2.5rem 1rem 0",
                  position: "relative",
                  zIndex: 2,
                }}
                component="img"
                src="https://d39flyyba0jiph.cloudfront.net/assets/home/desktop/image-hero-phone.png"
                alt="designo phone"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};
export default HeroCTACard;
