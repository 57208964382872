import { DashboardOutlined } from "@mui/icons-material";
import MainPage from "./pages/main";

const config = {
    application: {
        name: "Flow Logisti - פלו לוגיסטי",
        logo: "/static/images/flow-64-64.png",
        auth: {
            enableRegister: true,
        },
        commonConfig: {
            showOfflineDialog: false
        },
        theme: {
            mode: "light",
            primary: {
                main: "#232f3e"
            },
            secondary: {
                main: "#146eb4"
            },
        }
    },
    pages: [
        {
            path: '/',
            spec: {
                type: "Simple",
                object: <MainPage />
            }
        }
    ],
    dashboards: [
        // {
        //     path: "/admin",
        //     name: {
        //         type: "Literal",
        //         text: "Admin",
        //     },
        //     roles: ["admin"],
        //     items: [
        //         {
        //             path: "clients",
        //             name: {
        //                 type: "Localized",
        //                 text: "business.clients",
        //             },
        //             icon: <PeopleOutline />,
        //             hide: false,
        //             spec: {
        //                 type: "Simple",
        //                 object: <ClientsPage />,
        //                 fabs: [
        //                     {
        //                         color: "primary",
        //                         icon: <Add />,
        //                         onClick: () => {
        //                             window.location.href = '/admin/client';

        //                         }
        //                     }
        //                 ]
        //             }
        //         },{
        //             path: "client",
        //             name: {
        //                 type: "Localized",
        //                 text: "business.newClient",
        //             },
        //             icon: <BadgeOutlined />,
        //             hide: false,
        //             spec: {
        //                 type: "Simple",
        //                 object: <NewClientPage />,
        //             }
        //         }
        //     ]
        // },
        {
            path: "/business",
            name: {
                type: "Literal",
                text: "עסקים",
            },
            roles: ["business"],
            items: [
                {
                    path: "dashboard",
                    name: {
                        type: "Localized",
                        text: "business.dashboard",
                    },
                    icon: <DashboardOutlined />,
                    hide: false,
                    spec: {
                        type: "Simple",
                        object: <h1>Hi</h1>
                    }
                }
            ]
        }
    ],
    languages: {
        default: 'he',
        list: [
            { code: 'he', name: 'עברית' },
        ]
    }
}

export default config;