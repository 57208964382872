const useAWS = () => {
    const appId = process.env.REACT_APP_FIREBASE_APP_ID
    const apiKey = process.env.REACT_APP_FIREBASE_API_KEY
    const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
    const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID
    const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
    const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
    const measurmentId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID

    console.log(appId, apiKey, authDomain, projectId, storageBucket, messagingSenderId, measurmentId)
}

export default useAWS