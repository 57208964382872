const itemListReduce = (itemsList) => {
    const itemsMap = itemsList.reduce(
        (map, currentValue) => {
            map[currentValue.path] = currentValue;
            return map;
        },
        {}
    );
    return itemsMap
}

export { itemListReduce };