import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const ServicesAsymmetricGrid = () => {
  const theme = useTheme();
  const matchMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const gridDetails = [
    {
      title: "title 1",
      ctaLabel: "הצג עוד",
      img: "https://images.unsplash.com/photo-1568992687947-868a62a9f521?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1932&q=80",
    },
    {
      title: "title 2",
      ctaLabel: "קרא עוד",
      img: "https://plus.unsplash.com/premium_photo-1679511319373-c5b7e78b6f90?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",
    },
    {
      title: "title 3",
      ctaLabel: "לרכישה",
      img: "https://images.unsplash.com/photo-1545241047-6083a3684587?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE3fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=600&q=60",
    },
    {
      title: "title 3",
      ctaLabel: "לרכישה",
      img: "https://images.unsplash.com/photo-1545241047-6083a3684587?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE3fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=600&q=60",
    },
    {
      title: "title 1",
      ctaLabel: "הצג עוד",
      img: "https://images.unsplash.com/photo-1568992687947-868a62a9f521?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1932&q=80",
    },
  ];

  const gridHeight = 250;

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: !matchMdDown ? "1fr 1fr" : "auto",
          gridTemplateRows: matchMdDown ? "auto 1fr auto" : "auto",
          rowGap: "24px",
          columnGap: "24px",
        }}
      >
        {gridDetails.map((cardDetails, i) => (
          <Box
            sx={{
              gridColumn: i === 0 && !matchMdDown && "1 / 2",
              gridRow: i === 0 && !matchMdDown && "1 / 3",
              height: matchMdDown
                ? gridHeight
                : !matchMdDown && i === 0
                ? `${gridHeight * 2}`
                : gridHeight,
            }}
          >
            <Card
              sx={{
                borderRadius: 2,
                width: "100%",
                height: "100%",
                backgroundImage: `url(${cardDetails.img})`,
                // filter: "grayscale(0.65)",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                position: "relative",
              }}
              elevation={20}
            >
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  px: 2
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontWeight: 600,
                    fontSize: "5em",
                    color: "tomato",
                    // filter: "grayscale(0)",
                    position: "absolute",
                    zIndex: 2,
                    letterSpacing: '1.2rem'
                  }}
                >
                  {cardDetails.title}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                  display: "flex",
                  justifyContent: "center",
                  zIndex: 2,
                  alignItems: "center",
                  "&:hover": {
                    opacity: "1",
                    zIndex: 2,
                    backgroundColor: "#ff6347cf",
                    width: "100%",
                    height: "100%",
                    transition: "all 0.4s",
                    position: "absolute",
                  },
                }}
              >
                <Stack>
                  <Typography
                    variant="h2"
                    sx={{
                      fontWeight: 600,
                      fontSize: "5em",
                      color: "#464e60",
                      // position: "absolute",
                      // zIndex: 2,
                    }}
                  >
                    {cardDetails.title}
                  </Typography>
                  <Button
                    startIcon="<"
                    fullWidth
                    variant="text"
                    sx={{ color: "#464e60" }}
                  >
                    {cardDetails.ctaLabel}
                  </Button>
                </Stack>
              </Box>
            </Card>
          </Box>
        ))}
      </Box>
    </Container>
  );
};
export default ServicesAsymmetricGrid;
